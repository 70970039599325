import Helmet from 'react-helmet';
import { ReactComponent as ArrowSVG } from 'assets/icon-arrow-right.svg';
import styles from './index.module.scss';
import { useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { GlobalContext } from "../../storage"; // 21.12.22 SCH

const Problem = () => {
  const [uploaded, setUploaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { returnStorage } = useContext(GlobalContext); // 21.12.22 SCH
  const { epk_id, setEpkId } = returnStorage; // 21.12.22 SCH

  const handleNext = () => {
    console.log(epk_id);

    navigate('/return', {
      state: {
        epk_id,
      },
    });
  };

  const renderTip = () => {
    return (
      <div>
        <h1>無法成功歸還？</h1>
        <p>請手動輸入包裝編號(英文+數字)共八碼</p>
        <input
          type="package"
          pattern="[A-Z0-9]*"
          ref={inputRef}
          onChange={(event) => {
            setEpkId(event.target.value as any);
          }}
        />
        {/* <p>請依以下流程，將歸還點 QR Code 、包裝 QR Code 拍照上傳，我們將由專人協助您完成歸還。</p>
        <div className={styles.buttonContainer}>
          <button className={styles.uploaded}>
            <span>歸還點 QR Code 拍照/上傳</span>
            <ArrowSVG />
          </button>
          <button>
            <span>包裝 QR Code 拍照/上傳</span>
            <ArrowSVG />
          </button>
        </div> */}
        <div className={styles.footer}>
          <button
            onClick={() => {
              // setUploaded(true);
              handleNext();
            }}
          >
            <span>下一步</span>
            <ArrowSVG />
          </button>
        </div>
      </div>
    );
  };
  
  const renderSuccess = () => {
    return (
      <div>
        <h1>上傳成功！</h1>
        <p>
          請您直接將包裝投入歸還箱，我們將由專人協助您完成歸還。
          感謝您進行包裝歸還的行動，我們已收到您的問題回饋。歸還流程中造成您的不便真的非常抱歉，配客嘉將會持續優化系統，讓使用與歸還流程變得更加順暢！
        </p>
        <div className={styles.footer}>
          <button
            onClick={() => {
              navigate('/');
            }}
          >
            <span>返回主頁</span>
            <ArrowSVG />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet title="操作問題" />
      <div className={styles.problem}>{uploaded ? renderSuccess() : renderTip()}</div>
    </>
  );
};

export default Problem;
