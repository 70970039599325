import { useEffect, useRef, useContext } from 'react';
// import { ReactComponent as ArrowSVG } from 'assets/icon-arrow-right.svg';
import { ReactComponent as CheckMapSVG } from 'assets/icon-check-map.svg';
import { ReactComponent as TakePackageSVG } from 'assets/icon-take-package.svg';
import { ReactComponent as CouponSVG } from 'assets/icon-coupon-button.svg';
import { ReactComponent as MemberSVG } from 'assets/icon-member-button.svg';
import homeBanner from 'assets/homeBanner.jpg'; // 22.06.25 SCH
// import { ReactComponent as TreeSVG } from 'assets/icon-game-button-disable.svg';
// import { ReactComponent as LockSVG } from 'assets/icon-lock.svg';
import { useNavigate } from 'react-router';
import { GlobalContext } from '../../storage'; // 22.01.10 SCH
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation(); // 22.01.10 SCH
  const { returnStorage } = useContext(GlobalContext);
  const { chs_short_code, store_id } = returnStorage; // 22.02.10 SCH
  const { register_to_home, setRegisterToHome } = returnStorage; // 22.02.11 SCH

  useEffect(() => {
    // 22.02.10 SCH, 如果是直接掃描網址進來時，就直接進入['/return']
    console.log(
      'Home.useEffect Chs-storeId: ' + chs_short_code + '-' + store_id
    );
    if (chs_short_code && store_id && register_to_home) {
      setRegisterToHome(false); // 22.02.11 SCH
      // 22.01.10 SCH, 直接進入['/return']
      navigate('/return');
    }
  }, [
    pathname,
    chs_short_code,
    store_id,
    navigate,
    register_to_home,
    setRegisterToHome,
  ]);

  return (
    <div className={styles.home}>
      <div className={styles.banner}>
        <img src={homeBanner} alt="HomeBanner" />
      </div>
      <div
        className={`${styles.menu}`}
        onClick={() => {
          navigate('/rent');
        }}
      >
        <span>我要歸還</span>
        <TakePackageSVG />
      </div>
      <div
        className={`${styles.menu}`}
        onClick={() => {
          navigate('/nearby');
        }}
      >
        <span>查看地圖</span>
        <CheckMapSVG />
      </div>
      <div
        className={`${styles.menu} `}
        onClick={() => {
          navigate('/discount/ecommerce');
        }}
      >
        <span>我的優惠</span>
        <CouponSVG />
      </div>
      <div
        className={`${styles.menu}`}
        onClick={() => {
          navigate('/member/return');
        }}
      >
        <span>會員中心</span>
        <MemberSVG />
      </div>
      {/* <div className={`${styles.menu} ${styles.disabled}`}>
        <span>種樹遊戲</span>
        <TreeSVG />
        <div className={styles.lock}>
          <LockSVG />
        </div>
      </div> */}
    </div>
  );
};
export default Home;
