import styles from './index.module.scss';
import { Route, Routes, useLocation } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PaymentList from './paymentList';
import PaymentAdd from './paymentAdd';

const PaymentSetting = () => {
  const location = useLocation();
  return (
    <div className={styles.paymentSetting}>
      <h1>付款設定</h1>
      <div className={styles.container}>
        <TransitionGroup component={null}>
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Routes location={location}>
              <Route>
                <Route index element={<PaymentList />} />
                <Route path="add">
                  <Route index element={<PaymentAdd />} />
                </Route>
              </Route>
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
};

export default PaymentSetting;
