import { FC } from 'react';
import Dialog from '@mui/material/Dialog';
// import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogContentText from '@mui/material/DialogContentText';
import MuiDialogActions from '@mui/material/DialogActions';
import styled from '@mui/material/styles/styled';
// import { ReactComponent as CloseIcon } from '../../assets/icon-close.svg';
import styles from './index.module.scss';

// const CloseButton = styled('button')(({ theme }) => ({
//   outline: 'none',
//   background: 'none',
//   border: 'none',
//   color: 'black',
//   cursor: 'pointer',
//   transition: 'color .3s ease',
//   width: 24,
//   height: 24,
//   padding: 0,
//   display: 'inline-flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   '&:hover': {
//     color: theme.palette.grey[500],
//   },
//   '& svg': {
//     width: 20,
//     height: 20,
//   },
// }));

// const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
//   margin: 0,
//   padding: theme.spacing(1),
//   textAlign: 'right',
// }));

const DialogContent = styled(MuiDialogContent)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 15px',
}));

const DialogContentText = styled(MuiDialogContentText)(() => ({
  width: 285,
  textAlign: 'center',
  color: 'black !important',
  fontSize: '13px !important',
  marginBottom: 0,
}));

const DialogActions = styled(MuiDialogActions)(() => ({
  padding: '0 27px',
  paddingBottom: 8,
  display: 'flex',
  flexDirection: 'column',
  '& > :not(:first-of-type)': {
    marginLeft: 0,
    marginTop: 10,
  },
}));

interface CustomDialogProps {
  visible: boolean;
  onConfirm: () => void;
  message: string | React.ReactElement;
  cancelable?: boolean;
  onCancel?: () => void;
  onCancelButtonClick?: () => void;
  okText?: string;
  cancelText?: string;
  okButtonStyles?: string;
  showOk?: boolean;
  onBackdropClick?: () => void;
}

const CustomDialog: FC<CustomDialogProps> = (props) => {
  const {
    visible,
    onConfirm,
    message,
    cancelable,
    onCancel,
    onCancelButtonClick,
    okText,
    cancelText,
    okButtonStyles,
    showOk = true,
    onBackdropClick,
  } = props;
  // const onClose = cancelable ? onCancel : onConfirm;
  return (
    <Dialog
      open={visible}
      onClose={cancelable ? onCancel : onConfirm}
      onBackdropClick={onBackdropClick}
    >
      {/* <DialogTitle>
        {onClose ? (
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        ) : null}
      </DialogTitle> */}
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {showOk && (
          <button
            className={`${styles.actionButton} ${okButtonStyles}`}
            onClick={onConfirm}
          >
            {okText || '好'}
          </button>
        )}
        {cancelable && (
          <button
            className={`${styles.actionButton} ${styles.cancel}`}
            onClick={onCancelButtonClick || onCancel}
          >
            {cancelText || '否'}
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
