import { createContext, useState } from 'react';
// import { Response } from '../api/index';
// import { MeData } from '../api/me'; // 22.04.19 SCH

export const GlobalContext4User = createContext(undefined);

export const UserProvider = (props) => {
  const storage4user = {}
  const [ token, setToken ] = useState(""); // 權杖、通關密碼
  const [ user, setUser ] = useState([]); // User Data
  const [ line_pay, setLinePay ] = useState(0); // users.line_pay
  const [ user_role, setUserRole ] = useState(0); // users.role
  const [ user_type, setUserType ] = useState(""); // users.type

  storage4user['meUserStorage'] = {
    token, setToken, // 22.04.19 SCH
    user, setUser, // 22.04.19 SCH
    line_pay, setLinePay, // 22.11.02 SCH
    user_role, setUserRole, // 22.11.23 SCH
    user_type, setUserType, // 22.11.23 SCH
  }
  
  return (
    <GlobalContext4User.Provider value={storage4user}>
      {props.children}
    </GlobalContext4User.Provider>
  )
}
