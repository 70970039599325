import Tabs, { Tab } from 'components/Tabs';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

const Discount = () => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const { pathname } = useLocation();
  useEffect(() => {
    setActiveTab(1);
    if (pathname.indexOf('ecommerce') !== -1) {
      setActiveTab(1);
    } else if (pathname.indexOf('member') !== -1) {
      setActiveTab(2);
    } else {
      setActiveTab(0);
    }
  }, [pathname]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <div className={styles.discount}>
      <div className={styles.tabContainer}>
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label="歸還立即換" component={Link} to={`./`} />
          <Tab label="電商優惠券" component={Link} to={`ecommerce`} />
          <Tab disabled={true} label="會員優惠券" component={Link} to={`member`} />
        </Tabs>
      </div>
      <div className={styles.container}>
        <Outlet />
      </div>
    </div>
  );
};

export default Discount;
