import React, { FC, useEffect, useRef, useState } from 'react';
import { a, useSpring, config } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { ReactComponent as BackSVG } from 'assets/icon-back.svg';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import { closest } from 'utils/utils';
import styles from './index.module.scss';

export interface bottomSheetOptions {
  open: boolean;
  loading: boolean;
  onClose?: () => void;
  hideHeader?: boolean;
  peekHeights?: number[];
  threshold: number;
}

export interface BottomSheetProps extends Partial<bottomSheetOptions> {}

export interface bottomSheetStyles {
  backdrop?: Record<string, any>;
  background?: Record<string, any>;
  root?: Record<string, any>;
}

/** Some sensible defaults */
export const defaultOptions = {
  backdrop: true,
  background: null,
  peekHeights: [],
};
const WindowHeight = window.innerHeight - 49;
export const BottomSheet: FC<BottomSheetProps> = (props) => {
  /** Merge defaults and provided options */
  const { peekHeights, onClose, hideHeader, loading } = {
    ...defaultOptions,
    ...props,
  };
  const containerRef = useRef<HTMLDivElement | null>(null);
  /** Track heights */
  const [measureRef, { height }] = useMeasure({ polyfill: ResizeObserver });
  /** generate stop position relative to window height */
  const stopPosition = (relativeHeight: number) => height - relativeHeight;
  const [scrollable, setScrollable] = useState<boolean>(false);

  const stops: number[] = [0];

  /** Add peek heights if they are less than the max height */
  peekHeights?.sort().forEach((peekHeight) => {
    if (peekHeight < height && peekHeight < WindowHeight) {
      stops.push(stopPosition(peekHeight));
    }
  });
  stops.push(height);
  const actualHeight = height;
  const [{ y }, api] = useSpring(() => ({
    y: WindowHeight || 100,
  }));

  const [{ height: headerHeight, opacity, ...innerStyle }, headerSpring] =
    useSpring(() => ({
      from: { opacity: 0, y: 40, height: 10 },
    }));

  const open = ({ canceled }: { canceled: boolean }) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    api.start({
      y: stopPosition(peekHeights?.sort()[0]),
      immediate: false,
      config: canceled ? config.wobbly : config.stiff,
    });
  };
  const close = (velocity = 0) => {
    api.start({
      y: actualHeight,
      immediate: false,
      config: { ...config.stiff, velocity },
      onRest: () => {
        // console.log('onReset'); // 22.07.20 SCH 點掉!!
        setScrollable(false);
        onClose && onClose();
      },
    });
    headerSpring({
      y: 40,
      height: 10,
      opacity: 0,
      immediate: false,
      config: config.stiff,
    });
  };

  const bind = useDrag(
    ({
      last,
      velocity: [, vy],
      direction: [, dy],
      movement: [, my],
      cancel,
      canceled,
      lastOffset: [, oy],
    }) => {
      const actualY = my + oy;
      /** Prevent drag if container isn't at top of scroll */
      if (containerRef?.current?.scrollTop) {
        // console.log('prevented', containerRef?.current?.scrollTop);
        return;
      }

      // if (actualY < -70) {
      //   cancel && cancel();
      // }

      /** On release, snap to closest stop position */
      if (last) {
        const lastPosition = closest(actualY, stops);
        api({
          y: lastPosition,
          config: config.stiff,
        });
        if (lastPosition === 0) {
          setScrollable(true);
          headerSpring({ y: 0, height: 40, opacity: 1, config: config.stiff });
          // headerSpring.start();
        } else {
          headerSpring({ y: 40, height: 10, opacity: 0, config: config.stiff });
          setScrollable(false);
        }
        if (lastPosition === actualHeight) {
          close();
        }
        return;
      }
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      else {
        api.start({ y: actualY, immediate: true });
      }
    },
    {
      from: () => [0, y.get()],
      enabled: !loading,
      filterTaps: true,
      bounds: { top: 0 },
    }
  );

  const visibility = y.to((py) =>
    py < height && props.open ? 'visible' : 'hidden'
  );

  useEffect(() => {
    if (props.open) {
      open({ canceled: false });
    } else {
      close();
    }
    // eslint-disable-next-line
  }, [props.open, actualHeight, peekHeights]);
  return (
    <>
      <a.div
        ref={measureRef}
        className={styles.sheet}
        {...bind()}
        style={{
          visibility,
          y,
        }}
      >
        {!hideHeader && (
          <a.div
            className={styles.headerContainer}
            style={{
              height: headerHeight,
            }}
          >
            <a.div className={styles.header} style={{ opacity, ...innerStyle }}>
              <button
                onClick={() => {
                  close();
                }}
              >
                <BackSVG />
                <span>返回地圖</span>
              </button>
            </a.div>
          </a.div>
        )}
        <div
          ref={containerRef}
          className={`${styles.body} ${scrollable ? styles.scrollable : ''}`}
        >
          {props.children}
        </div>
      </a.div>
    </>
  );
};
