import { FC } from 'react';

interface AuthRouteProps {
  isLoggin: boolean;
  isMember: boolean;
  notPass: () => React.ReactElement;
  component: () => React.ReactElement;
}

const AuthRoute: FC<AuthRouteProps> = (props) => {
  const { isLoggin, isMember, notPass, component } = props;
  if (!isLoggin && !isMember) {
    return notPass();
  }
  return component();
};

export default AuthRoute;
