import zhLocale from 'date-fns/locale/zh-TW';
import { FormHelperText } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker, { MobileDatePickerProps } from '@mui/lab/MobileDatePicker';
import { forwardRef } from 'react';

interface CustomPickerType extends MobileDatePickerProps {
  label: string;
  error: boolean;
  helperText?: string | boolean;
}

const CustomPicker: React.FC<CustomPickerType> = forwardRef((props, ref) => {
  const { error, helperText, value, onChange, label, ...restProps } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhLocale}>
      <MobileDatePicker
        {...restProps}
        views={['year', 'month', 'day']}
        inputFormat="yyyy-MM-dd"
        mask="____-__-__"
        label={label}
        toolbarFormat="yyyy年MM月dd日"
        toolbarPlaceholder="請選擇日期"
        value={value}
        onChange={onChange}
        cancelText="取消"
        okText="確定"
      />
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </LocalizationProvider>
  );
});
export default CustomPicker;
