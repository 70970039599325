import styles from './index.module.scss';
import { ReactComponent as ArrowRightSVG } from 'assets/icon-arrow-right.svg';
// import { useLocation, useNavigate } from 'react-router';
import { useRef, useContext, useEffect } from 'react';
import { GlobalContext } from '../../storage'; // 22.10.11 SCH
// 22.04.19 SCH, Add storage4user['meUserStorage']
// 22.11.02 SCH, Add line_pay, setLinePay
import { GlobalContext4User } from '../../storage/user';

const PaymentAdd = () => {
  // const navigate = useNavigate();
  // const { pathname } = useLocation(); // 22.11.02 SCH
  const { linePayStorage } = useContext(GlobalContext);
  const { paymentUrlWeb } = linePayStorage; // 22.10.18 SCH
  // @ts-ignore // 22.04.19 SCH // 22.11.02 SCH
  const { meUserStorage } = useContext(GlobalContext4User);
  const { setLinePay } = meUserStorage; // 22.11.02 SCH
  const paymentUrl = useRef<string>();

  useEffect(() => {
    getLinePaymentUrl();
  });

  const getLinePaymentUrl = async () => {
    try {
      // confirmUrl 是「後端」在執行 reserve 後先存放在「Line」裡面！！
      // LinePay::getCallBackConfirmURL($orderId); // 22.10.18 SCH
      // $LinePay = new LinePay(); // 22.10.18 SCH
      // $response = $LinePay->reserve($params);
      // paymentUrlWeb = $response['info']['paymentUrl']['web']
      // from paymentMember.tsx (setPaymentUrlWeb(data.info.paymentUrl.web);)
      paymentUrl.current = paymentUrlWeb;
      console.log(paymentUrlWeb); // 22.10.18 SCH

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.paymentList}>
      {/* <div>
        <button
          onClick={() => {
            navigate('./creditcard');
          }}
        >
          <span>信用卡/簽帳卡</span>
          <ArrowRightSVG />
        </button>
      </div> */}
      <div>
        <button onClick={() => {
          // 22.11.02 SCH, 讓本畫面停留兩秒再執行 window.open()！
          setTimeout(() => {
            setLinePay(1); // 22.11.02 SCH, Line-綁定 !!
            window.open(paymentUrlWeb, 'Line-pay', );
          }, 2000);
        }}>
          <span>LINE Pay</span>
          <span>{{paymentUrlWeb} ? ' ... 轉至 LINE-付款頁面   ' 
                      : ' ... 請稍後兩秒   '}</span>
          <ArrowRightSVG />
        </button>
      </div>
    </div>
  );
};

export default PaymentAdd;
