import { ReactComponent as ArrowSVG } from 'assets/icon-arrow-right.svg';
import { ReactComponent as InfoSVG } from 'assets/icon-info.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

const Mock = [
  {
    id: 1,
    name: 'RETISSUE x PackAge+ …',
    date: '2021/11/11~2022/03/03',
    type: 'code',
    code: 'JE9Z0016HA3N',
  },
];

const MemberDiscount = () => {
  const [filters, setFilters] = useState<'all' | 'wholesale' | 'onlinstore'>('all');
  return (
    <>
      <div className={styles.filters}>
        <button
          className={filters === 'all' ? styles.active : undefined}
          onClick={() => {
            setFilters('all');
          }}
        >
          全部
        </button>
        <button
          className={filters === 'wholesale' ? styles.active : undefined}
          onClick={() => {
            setFilters('wholesale');
          }}
        >
          通路
        </button>
        <button
          className={filters === 'onlinstore' ? styles.active : undefined}
          onClick={() => {
            setFilters('onlinstore');
          }}
        >
          電商
        </button>
      </div>
      <div className={styles.list}>
        {Mock.map((discount) => (
          <div key={discount.id} className={styles.listItem}>
            <div className={styles.shadow}></div>
            <div className={styles.img}></div>
            <div className={styles.info}>
              <h5>{discount.name}</h5>
              <span className={styles.date}>{discount.date}</span>
              {discount.type === 'code' ? (
                <span className={styles.code}>{discount.code}</span>
              ) : (
                <button>
                  <span>選券</span>
                  <ArrowSVG />
                </button>
              )}
            </div>
            <Link to="../detail" className={styles.infoBtn}>
              <InfoSVG />
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};
export default MemberDiscount;
