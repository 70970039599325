import { useState, useContext } from 'react'; // 22.11.02 SCH
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContentText from '@mui/material/DialogContentText';
import styled from '@mui/material/styles/styled';
import styles from './emailDialog.module.scss';
import { ReactComponent as ArrowRightSVG } from 'assets/icon-arrow-right.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/Auth'; // 22.10.31 SCH
import { expiresLine } from 'api/linePay'; // 22.10.31 SCH
// 22.04.19 SCH, Add storage4user['meUserStorage']
// 22.11.02 SCH, Add line_pay, setLinePay
import { GlobalContext4User } from '../../../storage/user';

const DialogContent = styled(MuiDialogContent)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 24px',
  flexDirection: 'column',
}));

const DialogContentText = styled(MuiDialogContentText)(() => ({
  color: 'black !important',
  fontSize: 13,
  marginBottom: 0,
}));

const DialogActions = styled(MuiDialogActions)(() => ({
  padding: '0 27px',
  paddingBottom: '21px',
  display: 'flex',
  gap: 20,
}));

const UnlinkDialog = ({
  open,
  onBackdropClick,
}: {
  open: boolean;
  onBackdropClick: () => void;
}) => {
  const navigate = useNavigate();
  const { accessToken, me } = useAuth(); // 22.10.11 SCH
  // @ts-ignore // 22.04.19 SCH // 22.11.02 SCH
  const { meUserStorage } = useContext(GlobalContext4User);
  const { setLinePay } = meUserStorage; // 22.11.02 SCH

  const lineExpires = async () => {
    try {
      const { data } = await expiresLine(accessToken!, 1, {
        line_id: me?.line_id!,
        mobile_number: me?.mobile_number,
      });
      if (!data) {
        return 'Expires Line failure !!';
      } else {
        setLinePay(0); // 22.11.02 SCH, Line-解綁定 !!
        console.log('expiresLine()'); // 22.11.01 SCH
        console.log(data); // 22.11.01 SCH
        navigate('/'); // 22.11.01 SCH
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onConfirm = () => {
    lineExpires() // 22.10.31 SCH
    // demo only, result page should be redirected from line pay link page
    // navigate('/payment/setting/add');
  };

  return (
    <Dialog
      open={open}
      onBackdropClick={onBackdropClick}
      PaperProps={{
        sx: {
          width: 235,
        },
      }}
    >
      <DialogContent>
        <DialogContentText>
          請注意！若您解除綁定付款方式將有可能無法正常使用循環包裝服務！
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          className={`${styles.button} ${styles.rounded} ${styles.disabled}`}
          onClick={onBackdropClick}
        >
          <span>取消</span>
          <ArrowRightSVG />
        </button>

        <button
          className={`${styles.button} ${styles.rounded}`}
          onClick={onConfirm}
        >
          <span>確認</span>
          <ArrowRightSVG />
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default UnlinkDialog;
