import { useLocation, useParams } from 'react-router';
import { useContext } from 'react'; // 22.02.10 SCH
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import MenuTabBar from 'components/MenuTabBar';
import Home from 'pages/Home';
import Register from 'pages/Register';
import PaymentSetting from 'pages/PaymentSetting';
import CreditCard from 'pages/PaymentSetting/creditCard';
import MemberCenter from 'pages/MemberCenter';
import UsedHistory from 'pages/MemberCenter/usedHistory';
import ReturnHistory from 'pages/MemberCenter/returnHistory';
import Rent from 'pages/Rent';
import NearBy from 'pages/Nearby';
import ExclusiveDiscount from 'pages/Nearby/exclusiveDiscount';
import Terms from 'pages/Terms';
import Privacy from 'pages/Privacy';
import Discount from 'pages/Discount';
import OfflineDiscount from 'pages/Discount/offlineDiscount';
import CouponDetail from 'pages/Discount/couponDetail';
import OnlineDiscount from 'pages/Discount/onlineDiscount';
import MemberDiscount from 'pages/Discount/memberDiscount';
import Return from 'pages/Rent/return';
import ReturnForOther from 'pages/Rent/returnForOther';
import Problem from 'pages/Problem';
import PaymentHistory from 'pages/MemberCenter/paymentHistory';
import { useAuth } from 'context/Auth';
import AuthComponent from 'components/AuthComponent';
import { CircularProgress } from '@mui/material';
import styles from '../App.module.scss';
import { GlobalContext } from '../storage'; // 22.02.10 SCH
import DiscountInfo from './Discount/discountInfo';
import Profile from './MemberCenter/profile';
import Description from './PaymentSetting/description';
import LinkLinePay from './PaymentSetting/linkLinePay';
import LinkResult from './PaymentSetting/linkResult';
import PaymentMember from './PaymentSetting/paymentMember';

const Layout = () => {
  return (
    <>
      <Outlet />
      <MenuTabBar />
    </>
  );
};

const ToRegister = () => {
  const { search } = useLocation();
  return <Navigate replace to={{ pathname: `/register`, search }} />;
};

const ToRegisterAndSaveData = () => {
  // Route.path=":salesChannel/:storeId"; // 22.02.10 SCH
  const { salesChannel, storeId } = useParams();
  const { returnStorage } = useContext(GlobalContext);
  const { setChsShortCode, setStoreId } = returnStorage;
  const { setRegisterToHome } = returnStorage; // 22.02.11 SCH
  console.log(
    'ToRegisterAndSaveData Chs-storeId: ' + salesChannel + '-' + storeId
  );
  setChsShortCode(salesChannel); // 22.02.10 SCH, 上列有正常顯示資料!!
  setStoreId(storeId); // 22.02.10 SCH
  setStoreId(storeId); // 22.02.10 SCH
  setRegisterToHome(true); // 22.02.11 SCH

  const { search } = useLocation();
  return <Navigate replace to={{ pathname: `/register`, search }} />;
};

const CustomRoutes = () => {
  const { error: authError, logging, isMember, isNormal } = useAuth();
  const { search } = useLocation();
  if (logging) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }
  // authError
  // if (authError) {
  //   console.log(authError);
  //   // return <LoginFailed />;
  // }

  // 一般用戶頁面 Routes
  // if (isNormal) {
  //   return null
  // }
  return (
    <Routes>
      <Route
        path="register"
        element={
          !logging && isMember ? (
            <Navigate replace to={{ pathname: `/`, search }} />
          ) : (
            <Register />
          )
        }
      />
      {/* <Route
        path="/terms"
        element={<Terms />
        }
      /> */}
      <Route
        path="/terms"
        element={
          <AuthComponent
            isLoggin={logging}
            isMember={isMember}
            notPass={() => <ToRegister />}
            component={() => <Terms />}
          />
        }
      />
      <Route
        path="/privacy"
        element={
          <AuthComponent
            isLoggin={logging}
            isMember={isMember}
            notPass={() => <ToRegister />}
            component={() => <Privacy />}
          />
        }
      />
      <Route
        path="/"
        element={
          <AuthComponent
            isLoggin={logging}
            isMember={isMember}
            notPass={() => <ToRegister />}
            component={() => <Layout />}
          />
        }
      >
        <Route index element={<Home />} />
        <Route path="member" element={<MemberCenter />}>
          <Route index element={<UsedHistory />} />
          <Route path="return" element={<ReturnHistory />} />
          <Route path="payment" element={<PaymentHistory />} />
        </Route>
        <Route path="member/profile" element={<Profile />} />
        <Route path="nearby">
          <Route index element={<NearBy />} />
          <Route path=":shopId" element={<ExclusiveDiscount />} />
        </Route>
        <Route path="rent" element={<Rent />} />
        <Route path="discount" element={<Discount />}>
          <Route index element={<OfflineDiscount />} />
          <Route path="member" element={<MemberDiscount />} />
          <Route path="ecommerce" element={<OnlineDiscount />} />
        </Route>
        <Route path="discount/:discountId" element={<DiscountInfo />} />
        <Route
          path="discount/ecommerce/:discountId"
          element={<DiscountInfo />}
        />
      </Route>

      <Route path="return">
        <Route
          index
          element={
            <AuthComponent
              isLoggin={logging}
              isMember={isMember}
              notPass={() => <ToRegister />}
              component={() => <Return />}
            />
          }
        />
        <Route
          path=":salesChannel/:storeId"
          element={
            <AuthComponent
              isLoggin={logging}
              isMember={isMember}
              // notPass={() => <ToRegister />} // 22.02.10 SCH
              notPass={() => <ToRegisterAndSaveData />}
              component={() => <Return />}
            />
          }
        />
        <Route
          path="other"
          element={
            <AuthComponent
              isLoggin={logging}
              isMember={isMember}
              notPass={() => <ToRegister />}
              component={() => <ReturnForOther />}
            />
          }
        />
      </Route>

      <Route
        path="problem"
        element={
          <AuthComponent
            isLoggin={logging}
            isMember={isMember}
            notPass={() => <ToRegister />}
            component={() => <Problem />}
          />
        }
      />
      <Route
        path="discount/detail/*"
        element={
          <AuthComponent
            isLoggin={logging}
            isMember={isMember}
            notPass={() => <ToRegister />}
            component={() => <CouponDetail />}
          />
        }
      />
      <Route path="payment">
        <Route
          index
          element={
            <AuthComponent
              isLoggin={logging}
              isMember={isMember}
              notPass={() => <ToRegister />}
              component={() => <Description />}
            />
          }
        />
        <Route
          path="member"
          element={
            <AuthComponent
              isLoggin={logging}
              isMember={isMember}
              notPass={() => <ToRegister />}
              component={() => <PaymentMember />}
            />
          }
        />
        <Route path="link">
          <Route
            index
            element={
              <AuthComponent
                isLoggin={logging}
                isMember={isMember}
                notPass={() => <ToRegister />}
                component={() => <LinkLinePay />}
              />
            }
          />
          <Route
            path="result"
            element={
              <AuthComponent
                isLoggin={logging}
                isMember={isMember}
                notPass={() => <ToRegister />}
                component={() => <LinkResult />}
              />
            }
          />
        </Route>
        <Route
          path="setting/*"
          element={
            <AuthComponent
              isLoggin={logging}
              isMember={isMember}
              notPass={() => <ToRegister />}
              component={() => <PaymentSetting />}
            />
          }
        />
        <Route
          path="setting/add/creditcard"
          element={
            <AuthComponent
              isLoggin={logging}
              isMember={isMember}
              notPass={() => <ToRegister />}
              component={() => <CreditCard />}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default CustomRoutes;
