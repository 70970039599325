import { useState } from 'react';
import styles from './index.module.scss';

const Mock = [
  {
    id: 1,
    date: '2021.01.01 10:09',
    type: '訂閱會員(1年) beta',
    amount: '2388',
    payment: 'LINE Pay',
  },
  {
    id: 2,
    date: '2021.01.01 10:09',
    type: '單次租借 beta',
    amount: '19',
    payment: 'LINE Pay',
  },
];

const PaymentHistory = () => {
  const [filter, setFilter] = useState<'all' | 'rent' | 'subscription'>('all');
  return (
    <div className={styles.history}>
      <div className={styles.filter}>
        <button
          className={filter === 'all' ? styles.active : undefined}
          onClick={() => {
            setFilter('all');
          }}
        >
          全部
        </button>
        <button
          className={filter === 'rent' ? styles.active : undefined}
          onClick={() => {
            setFilter('rent');
          }}
        >
          租借
        </button>
        <button
          className={filter === 'subscription' ? styles.active : undefined}
          onClick={() => {
            setFilter('subscription');
          }}
        >
          訂閱
        </button>
      </div>
      <div className={styles.list}>
        {Mock.map((item) => (
          <div className={styles.listItem} key={item.id}>
            <div>
              <span>消費時間</span>
              <span>{item.date}</span>
            </div>
            <div>
              <span>類別</span>
              <span>{item.type}</span>
            </div>
            <div>
              <span>付款方式</span>
              <span>{item.payment}</span>
            </div>
            <div>
              <span>金額</span>
              <span>{item.amount}</span>
            </div>
          </div>
        ))}
      </div>
      <span className={styles.tip}>僅顯示最近3個月內的記錄</span>
    </div>
  );
};

export default PaymentHistory;
