import { Helmet } from 'react-helmet';
import { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import styles from './index.module.scss';
// import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import QrReader from 'libraries/react-qr-reader';
// import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import Dialog from 'components/Dialog';
// import { ReactComponent as ResultSVG } from 'assets/result.svg';
import { ReactComponent as ArrowSVG } from 'assets/icon-arrow-right.svg';
import { ReactComponent as ShopSVG } from 'assets/icon-shop.svg';
import { ReactComponent as TipSVG } from 'assets/image-return.svg';
import { ReactComponent as ReturnBoxSVG } from 'assets/image-return-box.svg';
import { ReactComponent as ReturnStaffSVG } from 'assets/image-return-staff.svg';
import LIFF20GIF from 'assets/LIFF20GIF.gif'; // 22.07.08 SCH
import Stepper from './stepper';
import { useAuth } from 'context/Auth'; // 21.12.10 SCH
import { getCoupons, CouponsData, ServicePointsData } from 'api/query';
import { saveUserRentLogs, Logs1Message } from 'api/return';
import { updateUserRentLogs } from 'api/return';
import { getChannelStoreBySC, QRCode1ApiData } from 'api/return';
import { getEpkType, QRCode2ApiData } from 'api/return';
import { chkEpkId } from 'api/return'; // 22.02.09 SCH
import { GlobalContext } from '../../storage'; // 21.12.17 SCH
import ManualModal from './manualModal';

const Return = () => {
  // const vipURL = process.env.REACT_APP_VIP_URL; // 2021.12.10 Mike
  const [step, setStep] = useState<number>(0);
  const [stopScan, setStopScan] = useState<boolean>(false);
  const [remindVisible, setRemindVisible] = useState<boolean>(false);
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [bonusVisible, setBonusVisible] = useState<boolean>(false);
  const [manualVisible, setManualVisible] = useState<boolean>(false);
  const [currentLocationlVisible, setCurrentLocationVisible] =
    useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  // 歸還類別： 歸還箱 or 店員 // 22.05.29 SCH
  const [returnType, setReturnType] = useState<'box' | 'staff'>('box');
  // 獎勵類別： 線上 or 線下 // 22.05.29 SCH
  const [bonusType, setBonusType] = useState<'online' | 'offline'>('offline');
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  // Route.path=":salesChannel/:storeId"; // 21.12.17 SCH
  const { salesChannel, storeId } = useParams();
  const { shortUrl } = useParams(); // 21.12.17 SCH
  // 通路代號 Ref
  const salesChannelRef = useRef<string>();
  // 商店代號 Ref
  const storeIdRef = useRef<string>();
  // 包裝代號 Ref
  const packageIdRef = useRef<string>();
  // lihi-shortUrl Ref // 21.12.16 SCH
  const shortUrlRef = useRef<string>();

  const { accessToken, me } = useAuth(); // 21.12.10 SCH
  const [coupons, setCoupons] = useState<CouponsData[]>();
  const [channelStores, setChannelStores] = useState<QRCode1ApiData>();
  const [epkType, setEpkType] = useState<QRCode2ApiData>();
  const [userRentLogs, setUserRentLogs] = useState<Logs1Message>();
  const coupon_id = useRef<number>(); // 21.12.17 SCH
  const { returnStorage } = useContext(GlobalContext);
  const { channel_name, setChannelName } = returnStorage;
  const { channel_id, setChannelId } = returnStorage;
  const { use_type, setUseType } = returnStorage; // 21.12.21 SCH
  const { store_id, setStoreId } = returnStorage; // 21.12.23 SCH ●●●●●●●●●●
  const { chs_short_code, setChsShortCode } = returnStorage; // 21.12.23 SCH
  const { svpt_name, setSvPtName } = returnStorage;
  const { svpt_id, setSvPtId } = returnStorage;
  const { epk_id, setEpkId } = returnStorage;
  const { type_name, setTypeName } = returnStorage;
  const { date_time, setDateTime } = returnStorage;
  const { renter_id, setRenterId } = returnStorage; // 21.12.22 SCH
  const stepRef = useRef<number>(0);
  const [epkIdFound, setEpkIdFound] = useState<boolean>(false);
  const epkId = useRef<string>(); // 22.02.09 SCH
  let due_start = '即日起'; // 22.07.06 SCH
  let due_end = '無期限'; // 22.07.06 SCH

  useEffect(() => {
    return () => {
      setStopScan(false); // 22.07.18 SCH
    };
  }, []);

  useEffect(() => {
    fetchCoupons(); // 21.12.16 SCH
    setRenterId(me?.id!); // 21.12.23 SCH
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (salesChannel && storeId) {
      storeIdRef.current = storeId;
      salesChannelRef.current = salesChannel;
      // 22.02.10 SCH, useEffect()因移除dependencies而[]有兩條黃色底線(OK啦)
      fetchChannelStoreBySC(); // 22.07.20 SCH
      console.log('useEffect Chs-storeId: ' + salesChannel + '-' + storeId);
      stepRef.current = 1;
      setStep(1);
    } else if (chs_short_code && store_id) {
      storeIdRef.current = store_id;
      salesChannelRef.current = chs_short_code;
      // 22.02.10 SCH, useEffect()因移除dependencies而[]有兩條黃色底線(OK啦)
      fetchChannelStoreBySC(); // 22.07.20 SCH
      console.log('useEffect Chs-storeId: ' + chs_short_code + '-' + store_id);
      stepRef.current = 1;
      setStep(1);
    }
  }, []);
  // 21.12.17 SCH, By Lihi shortUrl 或 salesChannel + storeId 找出 ServicePoint, 二擇一！
  useEffect(() => {
    // 22.02.10 SCH, 這一段不會被執行, 因為 Lihi shortUrl 會自動轉為 liff.line.me 網址
    // 所以上面 useEffect() 的 if (salesChannel && storeId) {} 會為 true 並且搶先執行
    if (shortUrl) { // 22.07.06 SCH, 版面會多出一個外框！已不使用！
      shortUrlRef.current = shortUrl; // 21.12.17 SCH
      // 22.02.10 SCH, useEffect()因移除dependencies而[]有兩條黃色底線(OK啦)
      fetchChannelStoreBySC(); // 22.07.20 SCH
      console.log('useEffect shortUrl: ' + shortUrl);
      stepRef.current = 1; // Mike
      setStep(1);
    }
  }, []);


  const fetchCoupons = async () => {
    try {
      const { data } = await getCoupons(accessToken!, 1);
      if (!data) {
        // 22.01.05 SCH
        return 'Have not any valid coupons !!';
      } else {
        setCoupons(data);
        console.log('getCoupons()'); // 22.10.31 SCH
        console.log(data); // 22.10.31 SCH
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChannelStoreBySC = async () => {
    try {
      const { data } = await getChannelStoreBySC(accessToken!, 1, {
        chs_short_code: salesChannelRef.current,
        store_id: storeIdRef.current,
        lihi_url: shortUrlRef.current, // 21.12.14 SCH
      });
      setChannelStores(data);
      // 21.12.17 SCH, 將資料存入到 storage !!
      setChannelName(data?.channel_name);
      setChannelId(data?.channel_id);
      setUseType(data?.use_type); // 21.12.21 SCH
      setSvPtName(data?.svpt_name);
      setSvPtId(data?.svpt_id);
      // possible set return type here ?
      setReturnType(data?.return_type!); // 22.05.29 SCH

      if (coupons) { // 22.07.06 SCH
        setBonusType('online');  // 22.07.06 SCH
      } else {
        setBonusType('offline'); // 22.07.06 SCH
      }

      console.log('salesChannel: ' + salesChannel);
      console.log('storeId: ' + storeId);
      setStoreId(storeIdRef.current); // 21.12.23 SCH ●●●●●●●●●
      console.log(store_id); // 21.12.23 SCH (undefined)
      setChsShortCode(salesChannelRef.current); // 21.12.23 SCH
      console.log(chs_short_code); // 21.12.23 SCH (undefined)
      if (data) {
        if (state && state.phone) {
          setCurrentLocationVisible(false); // 22.07.10 SCH
        } else {
          setCurrentLocationVisible(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEpkType = async () => {
    try {
      const { data } = await getEpkType(accessToken!, 1, {
        epk_id: packageIdRef.current,
      });
      setEpkType(data);
      // 21.12.17 SCH, 將資料存入到 storage !!
      setEpkId(packageIdRef.current);
      setTypeName(data?.type_name);
      setDateTime(data?.date_time);
    } catch (error) {
      console.log(error);
    }
  };

  const addUserRentLogs = async () => {
    try {
      const { data } = await saveUserRentLogs(accessToken!, 1, {
        epk_id: epk_id,
        renter_id: renter_id, // 21.12.23 SCH
        return_user_id: me?.id!, // 21.12.18 SCH
        chs_id: channel_id,
        svpt_id: svpt_id,
        return_type: 0,
      });
      setUserRentLogs(data);

      // if (state && state.phone && detailVisible) {
        // 幫別人還 // 22.11.15 SCH, 無條件再判斷一次!!
        if (coupons) {
          setBonusType('online');
        } else {
          setBonusType('offline');
        }
      // }
      
    } catch (error) {
      console.log(error);
    }
  };

  const changeUserRentLogs = async () => {
    console.log('userRentLog_id: ' + userRentLogs?.userRentLog_id);
    console.log('coupon_id: ' + coupon_id.current);
    try {
      const { data } = await updateUserRentLogs(accessToken!, 1, {
        id: userRentLogs?.userRentLog_id,
        userRentLog_id: userRentLogs?.userRentLog_id,
        coupon_id: coupon_id.current,
      });
      console.log('updateUserRentLogs()'); // 22.10.31 SCH
      console.log(data); // 22.10.31 SCH
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (state && state.epk_id) {
      setStopScan(true); // 21.12.22 SCH
      packageIdRef.current = epk_id;
    }
  }, [state, epk_id]);

  useEffect(() => {
    if (state && state.phone) {
      setStep(2);
      setStopScan(true); // 21.12.18 SCH
      setDetailVisible(true);
    }
  }, [state]);

  useEffect(() => {
    if (state && state.phone && detailVisible) {
      setChannelStores({
        channel_name: channel_name,
        channel_id: channel_id,
        use_type: use_type, // 21.12.21 SCH
        svpt_name: svpt_name,
        svpt_id: svpt_id,
        chs_short_code: chs_short_code, // 22.02.09 SCH
        store_id: store_id, // 22.02.09 SCH
        return_type: returnType, // 22.05.29 SCH
      });
      packageIdRef.current = epk_id;
      setEpkType({
        type_name: type_name,
        date_time: date_time,
      });
    }
  }, [state, detailVisible, channel_name, channel_id, use_type,
    svpt_name, svpt_id, epk_id, type_name, date_time, renter_id,
    pathname, chs_short_code, store_id, returnType ]);

  const onConfirm = () => {
    // 幫自己還
    addUserRentLogs(); // 21.12.10 SCH

    setRemindVisible(false);
    setDetailVisible(true);
  };

  const onCancel = () => {
    // 幫別人還
    setRemindVisible(false);
    // 2021.12.10 Mike, 為lihi短網址直接進系統，修改路徑的寫法!!
    let nowPath = pathname;
    console.log(nowPath);
    let words = nowPath.split('/');
    console.log(words);
    console.log(words.length);
    if (words.length === 2) {
      navigate('./other');
    } else {
      if (words.length === 3) {
        navigate('other');
      }
      if (words.length === 4) {
        navigate('../other');
      }
      if (words.length === 5) {
        navigate('../../other');
      }
      if (words.length === 6) {
        navigate('../../../other');
      }
    }
  };

  const onDetailConfirm = () => {
    if (state && state.phone && detailVisible) {
      fetchCoupons(); // 22.11.01 SCH, 重抓!!
      addUserRentLogs(); // 21.12.18 SCH
    }
    setStep(2);
    // setStopScan(true); // 21.12.21 Mike
    setDetailVisible(false);
    setDone(true);
    // 21.12.24 SCH, 讓「歸還成功」的畫面停留兩秒再切換「對話框」！
    // setTimeout(() => {
    //   setBonusVisible(true);
    // }, 2000);
  };

  const findEpkId = async () => {
    try {
      const { data } = await chkEpkId(accessToken!, 1, {
        epk_id: epkId.current,
      });
      if (data) {
        console.log(data);
        // 22.02.09 SCH, 將資料存入到 storage !!
        console.log('epk_id：' + data?.epk_id);
        setEpkId(data?.epk_id); // 22.02.09 SCH
        setEpkIdFound(true);
      } else {
        setEpkIdFound(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onBonusConfirm = () => {
    setBonusVisible(false);
    navigate('/discount/offline');
  };

  const handleError = (err: any) => {
    console.error(err);
  };
  const handleScan = async (result: any) => {
    const returnSpotRegex =
      /https:\/\/liff\.line\.me\/(?<liffId>[^\/\s]+)\/return\/(?<salesChannel>[^\/\s]+)\/(?<storeId>[^\/\s]+)/;
    const lihiRegex = /https:\/\/lihi1\.com\/(?<shortUrl>[^\/\s]+)/;

    if (result && !stopScan) {
      if (stepRef.current === 0) {
        // const [,shortUrl] 只會取到 hash, const [shortUrl]會包含 https 跟網域
        const [shortUrl] = result.match(lihiRegex) || []; // 要加 || [] 21.12.14 SCH
        shortUrlRef.current = shortUrl; // 21.12.15 Mike
        console.log('lihi shortUrl: ' + shortUrlRef.current);
        // Lihi shortUrl
        const [, , salesChannel, storeId] = result.match(returnSpotRegex) || [];
        if (shortUrl) {
          shortUrlRef.current = shortUrl; // 21.12.15 Mike
          // 已取得 lihi短網址 並切換step至(1)取得包裝ID
          fetchChannelStoreBySC(); // 21.12.14 SCH
        } else {
          if (salesChannel && storeId) {
            salesChannelRef.current = salesChannel;
            storeIdRef.current = storeId;
            console.log('salesChannel: ' + salesChannel);
            console.log('storeId: ' + storeId);
            // 已取得 通路代號(salesChannel) 與 商店ID (sotreID)
            fetchChannelStoreBySC(); // 21.12.11 SCH
          }
        }
      }
      if (stepRef.current === 1) {
        console.log('包裝編號：' + result + ' , ' + result.length); // 21.12.11 SCH
        if (result.length >= 6 && result.indexOf('https') < 0) {
          if (!channel_name || !svpt_name) {
            fetchChannelStoreBySC();
          }
          // 22.02.10 SCH, 讓本段程序先停留(0.1)秒後再執行{...}！
          setTimeout(() => {
            epkId.current = result; // 22.02.09 SCH
            findEpkId(); // 22.02.09 SCH
            if (epkIdFound) {
              // 22.02.09 SCH
              packageIdRef.current = result;
              stepRef.current = 2;
              setRemindVisible(true);
              // 已取得 包裝代號(packageId)
              fetchEpkType(); // 21.12.11 SCH
            }
          }, 100);
        }
      }
    }
  };
  const onCurrentLocationConfirm = () => {
    stepRef.current = 1;
    setStep(1);
  };
  const onManualConfirm = (data?: ServicePointsData | string) => {
    if (step === 0) {
      salesChannelRef.current = chs_short_code;
      storeIdRef.current = store_id; // 21.12.24 SCH
      shortUrlRef.current = 'noUrl'; // 22.02.09 SCH
      fetchChannelStoreBySC(); // 21.12.24 SCH
      console.log(data); // undefined (無任何資料)
      stepRef.current = 1;
      setManualVisible(false);
      setStep(1);
    }
    if (step === 1) {
      if (!channel_name || !svpt_name) {
        // 22.02.09 SCH
        fetchChannelStoreBySC();
      }
      packageIdRef.current = epk_id;
      fetchEpkType(); // 21.12.24 SCH
      console.log(data); // undefined (無任何資料)
      stepRef.current = 2;
      setManualVisible(false);
      setRemindVisible(true);
    }
  };

  return (
    <>
      <Helmet title="歸還" />
      <div className={styles.return}>
        {done ? (
          <div className={styles.result}>
            {returnType === 'box' ? <ReturnBoxSVG /> : <ReturnStaffSVG />}
            <div
              className={styles.button}
              onClick={() => {
                setBonusVisible(true);
              }}
            >
              <div>
                <span>
                  {returnType === 'box' ? '包裝投進歸還箱了' : '包裝交給店員了'}
                </span>
              </div>
              <ArrowSVG />
            </div>
          </div>
        ) : (
          <div className={styles.cameraContainer}>
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%', height: '100%' }}
              showViewFinder
              containerStyle={{
                width: '100%',
                height: '100%',
                position: 'relative',
                overflow: 'hidden',
              }}
              viewFinder={
                <div className={styles.scannerContainer}>
                  <div className={styles.scannerInner}/>
                </div>
              }
            />
            {step === 1 && (
              <div className={styles.selectedStore}>
                <ShopSVG />
                <span>
                  {channel_name}-{svpt_name}
                </span>
              </div>
            )}
            {step === 2 && (
              <div className={styles.selectedStore}>
                <ShopSVG />
                <span>{epk_id}</span>
              </div>
            )}
          </div>
        )}
        <div className={styles.footer}>
          <Stepper step={step} />
          <button
            style={step === 1 ? { opacity: 1 } : { opacity: 0 }}
            onClick={() => {
              setManualVisible(true);
            }}
          >
            無法掃描？
          </button>
        </div>
      </div>
      <Dialog
        visible={remindVisible}
        onConfirm={onConfirm}
        onCancel={onCancel}
        message={
          <div className={styles.dialogHeader}>
            <h5>提醒</h5>
            <p>
              請問您是自己歸還，
              <br />
              還是幫家人、朋友歸還包裝呢？
              <br />
            </p>
            <p>包裝編號：{epk_id}</p>
          </div>
        }
        cancelText="我幫別人還"
        okText="我幫自己還"
        cancelable
      />
      <Dialog
        visible={detailVisible}
        onConfirm={onDetailConfirm}
        message={
          <div className={styles.detailDialogHeader}>
            <h5>包裝歸還明細</h5>
            <div className={styles.content}>
              <div>
                <span>歸還時間</span>
                {/* <span>{returnTime.current}</span> */}
                <span>{epkType?.date_time}</span>
              </div>
              <div>
                <span>歸還地點</span>
                {/* <span>{salesChannelRef.current}</span> */}
                <span>
                  {channelStores?.channel_name}-{channelStores?.svpt_name}
                </span>
              </div>
              <div>
                <span>包裝類別</span>
                {/* <span>{packageIdRef.current}</span> */}
                <span>{epkType?.type_name}</span>
              </div>
              {state && state.phone && (
                <>
                  <div>
                    <span>歸還者</span>
                    {/* <span>{state.phone}</span> */}
                    <span>
                      {me!.realname !== '' ? me!.realname : me!.mobile_number}
                    </span>
                  </div>
                  <div>
                    <span>使用者</span>
                    <span>{state.phone}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        }
        okText="我知道了"
        okButtonStyles={styles.okButtonStyles}
      />
      <Dialog
        visible={currentLocationlVisible}
        onConfirm={onCurrentLocationConfirm}
        onBackdropClick={() => {
          setCurrentLocationVisible(false);
        }}
        message={
          <div className={styles.currentLocation}>
            <p className={styles.title}>您目前在</p>
            <p className={styles.shopName}>
              {channelStores?.channel_name}{channelStores?.svpt_name}
            </p>
            <div>
              {/*<TipSVG />*/}
              <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={LIFF20GIF} alt="gif"/>
            </div>
            <div className={styles.tip}>
              <p>接下來請掃描</p>
              <p>「包裝編號」QR Code</p>
            </div>
            <button
                className={styles.okButtonStyles}
                onClick={() => {
                  setCurrentLocationVisible(false);
                  onCurrentLocationConfirm(); // 22.07.10 SCH
                }}
            >
              <div style={{fontSize: '18px', color: 'green'}}>
                <span>「確認」</span>
                <span><ArrowSVG/></span>
              </div>
            </button>
          </div>
        }
        showOk={false}
      />
      <Dialog
        visible={bonusVisible}
        onConfirm={onBonusConfirm}
        message={
          <div className={styles.bonusDialogHeader}>
            {/* <p>限時額外獎勵！電商優惠券三選一</p> vip v2.0 之後就改為全部領取!? */}
            <h5>{bonusType === 'online' ? '限時額外獎勵' : '本次獎勵'}</h5>
            <div className={styles.content}>
              {/* <h1>{userRentLogs?.svpt_info}</h1>
              <h3>{coupons ? '' : '抱歉！優惠券皆已過期'}</h3> */}
              <h2>{channelStores?.svpt_name}</h2>
              <h2>{userRentLogs?.svpt_info}</h2>
              {bonusType === 'online' ? (
                <div className={styles.list}>
                  {coupons?.map((item) => (
                    <div className={styles.listItem} key={item.id}>
                      <div className={styles.coupon}>
                        <div className={styles.infoContainer}>
                          <span className={styles.title}>{item.title}</span>
                          <span className={styles.date}>
                            {item.start_at === null ? due_start : item.start_at.slice(0,10)}~{item.expired_at === null ? due_end : item.expired_at.slice(0,10)}
                          </span>
                        </div>
                      <div className={styles.btnContainer}>
                        <button
                          onClick={() => {
                            coupon_id.current = item.id; // 21.12.17 SCH
                            // 21.12.26 SCH, 讓本「Dialog」的畫面停留一秒再執行 changeUserRentLogs()！
                            setTimeout(() => {
                              changeUserRentLogs(); // 21.12.26 SCH
                            }, 1000);
                            setTimeout(() => {
                              navigate('/discount/');
                              // navigate('/discount/ecommerce');
                            }, 500);
                          }}
                        >
                          領取
                        </button>
                      </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <div>
                    <h2>{channelStores?.svpt_name}</h2>
                    <h2>{userRentLogs?.svpt_info}</h2>
                  </div>
                  <button
                    className={styles.collectAllBtn}
                    onClick={() => {
                      navigate('/discount/');
                    }}
                  >
                    <div>
                      <span>知道了</span>
                    </div>
                    <ArrowSVG />
                  </button>
                </div>
              )}
            </div>
            {/* <button
              className={styles.collectAllBtn}
              onClick={() => {
                setTimeout(() => {
                  // 22.07.20 SCH, 還原 !! coupon
                  // online: collect coupon here
                  setTimeout(() => {
                    changeUserRentLogs(); // 21.12.26 SCH
                  }, 1000);
                  navigate('/discount/');
                  // navigate('/discount/ecommerce');
                }, 500);
              }}
            >
              <div>
                <span>{bonusType === 'online' ? '全部領取' : '領取'}</span>
              </div>
              <ArrowSVG />
            </button> */}
            <p>本次獎勵優惠券將發放至您的「優惠」頁面</p>
          </div>
        }
        showOk={false}
        okButtonStyles={styles.okButtonStyles}
      />
      <ManualModal
        visible={manualVisible}
        step={step}
        onConfirm={onManualConfirm}
        onClose={() => {
          setManualVisible(false);
        }}
      />
    </>
  );
};

export default Return;
