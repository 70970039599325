import { FC, useRef } from 'react';
import styles from './index.module.scss';

type StepperProps = {
  step: number;
};

const Stepper: FC<StepperProps> = ({ step }) => {
  const stepperRef = useRef<HTMLDivElement>(null);

  const indicatorStyle = {
    transform: `translateX(${
      step === 0
        ? 0
        : step === 1
        ? (stepperRef.current?.clientWidth! - 20) / 2
        : stepperRef.current?.clientWidth! - 20
    }px)`,
  };
  return (
    <div ref={stepperRef} className={styles.stepper}>
      <div className={styles.steps}>
        <div
          className={
            step === 0 ? `${styles.step} ${styles.active}` : styles.step
          }
        >
          STEP 1
        </div>
        <div
          className={
            step === 1 ? `${styles.step} ${styles.active}` : styles.step
          }
        >
          STEP 2
        </div>
        <div
          className={
            step === 2 ? `${styles.step} ${styles.active}` : styles.step
          }
        >
          完成
        </div>
        <div className={styles.indicator} style={indicatorStyle}></div>
      </div>
      <div className={styles.content}>
        <span
          className={
            step === 0 ? `${styles.stepDesc} ${styles.active}` : styles.stepDesc
          }
        >
          請掃描「歸還點」上的 QR Code
        </span>
        <span
          className={
            step === 1 ? `${styles.stepDesc} ${styles.active}` : styles.stepDesc
          }
        >
          請掃描「包裝」上的 QR Code
        </span>
        <span
          className={
            step === 2 ? `${styles.stepDesc} ${styles.active}` : styles.stepDesc
          }
        >
          點擊上方按鈕結束歸還，領取優惠
        </span>
      </div>
    </div>
  );
};

export default Stepper;
