import React, { useEffect } from 'react';
import { Player } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css";
import { ReactComponent as ArrowSVG } from 'assets/icon-arrow-right.svg';
import { ReactComponent as ReturnBoxSVG } from 'assets/icon-returnbox.svg';
import { useLocation, useNavigate } from 'react-router';
import styles from './index.module.scss';

const Rent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let source = require('../../assets/line-return.mp4').default;

  useEffect( () => {
  }, [pathname])

  return (
    <div className={styles.rent}>
      <div className={styles.imagesCarousel}>
        <div>
          <Player
            /* src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" */
            src={source}
            fluid={false}
            height={148}
            width={315}
            preload='auto'
            autoPlay={false}
          />
        </div>
      </div>
    <div
      className={`${styles.menu} ${styles.description}`}
      onClick={() => {
        navigate('/return');
      }}
    >
      <div>
        <span>點我開始歸還</span>
        <span>有借有還，循環不難！</span>
      </div>
      <div>
        <ReturnBoxSVG />
      </div>
    </div>
    <div
      className={`${styles.menu} ${styles.lookaround}`}
      onClick={() => {
        navigate('/nearby');
      }}
    >
    <div>
      <span>查看附近</span>
      </div>
        <ArrowSVG />
      </div>
    </div>
  );
};

export default Rent;
