import { isBefore, isAfter, isEqual } from 'date-fns';

export const isSameOrBefore = (date: Date, comparDate: Date) =>
  isBefore(date, comparDate) || isEqual(date, comparDate);
export const isSameOrAfter = (date: Date, comparDate: Date) =>
  isAfter(date, comparDate) || isEqual(date, comparDate);

export const closest = (goal: number, numbers: number[]) =>
  numbers.reduce((prev, pos) =>
    Math.abs(pos - goal) < Math.abs(prev - goal) ? pos : prev
  );
