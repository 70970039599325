import Helmet from 'react-helmet';
import styles from './index.module.scss';

const Terms = () => {
  return (
    <>
      <Helmet title="使用條款" />
      <div className={styles.terms}>
        <div>
          <p>使用條款使用條款使用條款使用條款使用條款使用條款使用條款使用條款</p>
        </div>
      </div>
    </>
  );
};
export default Terms;
