import { FC } from 'react';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import styles from './index.module.scss';

const CustomTextField: FC<StandardTextFieldProps> = (props) => {
  const { placeholder, type, defaultValue, value, id, ...restProps } = props;

  return (
    <div className={styles.textField}>
      <TextField
        variant="standard"
        id={id} // 22.04.19 SCH
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        {...restProps}
      />
    </div>
  );
};

export default CustomTextField;
