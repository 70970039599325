import { useState, useEffect } from 'react';
import { ReactComponent as SuccessfulSVG } from 'assets/successfullink.svg';
import SubmitButton from 'components/SubmitButton';
// import { useNavigate } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { getMe, MeData } from "../../api/me"; // 22.10.24 SCH
import { useAuth } from 'context/Auth'; // 22.10.24 SCH
import styles from './index.module.scss';
// 22.04.19 SCH, Add storage4user['meUserStorage']
// 22.11.02 SCH, Add line_pay, setLinePay
import { GlobalContext4User } from '../../storage/user';

const LinkResult = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation(); // 22.10.24 SCH
  const { accessToken } = useAuth(); // 22.10.24 SCH
  const [me, setMe] = useState<MeData>();
    // @ts-ignore // 22.04.19 SCH // 22.11.02 SCH
    const { meUserStorage } = useContext(GlobalContext4User);
    const { setLinePay } = meUserStorage; // 22.11.02 SCH
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchMe = async () => {
    await getMe(accessToken!).then(resp => {
          console.log('getMe.resp'); // 22.10.24 SCH
          console.log(resp); // 22.10.24 SCH, OK
          setMe(resp.data);
          setLinePay(1); // 22.11.02 SCH, Line-綁定 !!
        }
    );
  }

  // 設置 web-page.lifecycle // 22.04.18 SCH
  useEffect(() => {
    // componentDidMount 及 componentDidUpdate
    fetchMe();
    // componentDidUpdate 及 componentWillUnmount
    return (() => {
      // console.log(`Before Update: State ${user.realname}`);
    })
  }, [pathname, fetchMe]);

  return (
    <div className={styles.linkResult}>
      <div className={styles.container}>
        <SuccessfulSVG />
        <div className={styles.info}>
          <div className={styles.inner}>
            <div className={styles.header}>
              <div>
                <span>付款方式</span>
              </div>
              {/* <div>
                <span>信用卡號</span>
              </div> */}
              <div>
                <span>綁定時間</span>
              </div>
            </div>
            <div className={styles.content}>
              <div>
                <span>Line Pay</span>
              </div>
              {/* <div>
                <span>**********1234</span>
              </div> */}
              <div>
                <span>{me?.updated_at}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <SubmitButton
          text="確認"
          onClick={() => {
            navigate('/');
          }}
        />
      </div>
    </div>
  );
};

export default LinkResult;
