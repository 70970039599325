import Helmet from 'react-helmet';
import styles from './index.module.scss';

const Privacy = () => {
  return (
    <>
      <Helmet title="隱私政策" />
      <div className={styles.privacy}>
        <div>
          <p>隱私政策隱私政策隱私政策隱私政策隱私政策隱私政策隱私政策隱私政策</p>
        </div>
      </div>
    </>
  );
};
export default Privacy;
