import styles from './index.module.scss';
import { ReactComponent as LinePaySvg } from 'assets/linepay.svg';
// import { useNavigate } from 'react-router-dom'; // 22.10.24 SCH
import { useLocation, useNavigate } from 'react-router';
import { useState, useContext, useEffect } from 'react';
import UnlinkDialog from './components/unlinkDialog';
import { useAuth } from 'context/Auth'; // 22.10.11 SCH
import { connectLine, LinePay1ResData } from 'api/linePay';
import { GlobalContext } from '../../storage'; // 22.10.11 SCH
// 22.04.19 SCH, Add storage4user['meUserStorage']
// 22.11.02 SCH, Add line_pay, setLinePay
import { GlobalContext4User } from '../../storage/user';

const PaymentMember = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation(); // 22.10.24 SCH
  const { accessToken, me } = useAuth(); // 22.10.11 SCH
  const [ linePay1, setLinePay1 ] = useState<LinePay1ResData>();
  const { linePayStorage } = useContext(GlobalContext);
  const { setPaymentAccessToken } = linePayStorage;
  const { setPaymentUrlWeb } = linePayStorage;
  const { setTransationId } = linePayStorage;
  // @ts-ignore // 22.04.19 SCH // 22.11.02 SCH
  const { meUserStorage } = useContext(GlobalContext4User);
  const { line_pay } = meUserStorage; // 22.11.02 SCH

  const lineConnect = async () => {
    try {
      const { data } = await connectLine(accessToken!, 1, {
        line_id: me?.line_id!,
        mobile_number: me?.mobile_number,
        currency: 'TWD',
        amount: 1,
      });
      if (!data) {
        return 'Connect Line failure !!';
      } else {
        setLinePay1(data);
        setPaymentAccessToken(data.info.paymentAccessToken);
        setPaymentUrlWeb(data.info.paymentUrl.web); // 22.10.18 SCH (app|web)
        setTransationId(data.info.transactionId);
      }
      console.log('data: ', data); // 22.10.18 SCH
    } catch (error) {
      console.log(error);
    }
  };

  const onBackdropClick = () => {
    setVisible(false);
  };

  useEffect( () => {
    console.log('me', me); // 22.10.24 SCH
  }, [pathname, me])

  // 22.10.24 SCH  me?.line_pay === 0 (沒考慮:User解綁定)
  // 22.11.02 SCH  line_pay === 0 (正確顯示未綁定及已綁定)
  return (
    <>
      <div className={styles.paymentSetting}>
        <h1>付款設定</h1>
        <div className={styles.memberMethods}>
          <div className={styles.methodList}>
            {(line_pay === 0) ? (
              <div className={styles.method}>
                <div>
                  <LinePaySvg />
                    <span>未綁定</span>
                </div>
              <div>
              <button
                onClick={() => {
                  setTimeout(() => {
                    setTimeout(() => {
                      lineConnect(); // 22.10.11 SCH
                    }, 1000);
                    navigate('/payment/setting/add');
                  }, 500);
                }}
              >
                綁定
              </button>
              </div>
            </div>
            ) : (
              <div className={styles.method}>
              <div>
                <LinePaySvg />
                <span>已綁定</span>
              </div>
              <div>
                <button
                  className={styles.disabled}
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  解除綁定
                </button>
              </div>
            </div>
            )}
          </div>

          <div className={styles.tips}>
            <p>
              費用僅會在循環包裝未在14天內歸還以Linepay自動扣款。詳細規定請至
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.packageplus-tw.com/pages/questionsandanswers"
              >
                Q&A
              </a>
              了解。
            </p>
          </div>
        </div>
      </div>

      <UnlinkDialog open={visible} onBackdropClick={onBackdropClick} />
    </>
  );
};

export default PaymentMember;
