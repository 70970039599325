import SubmitButton from 'components/SubmitButton';
import styles from './index.module.scss';
import { ReactComponent as PaymentSVG } from 'assets/payment.svg';
import { useNavigate } from 'react-router-dom';

const Description = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.paymentDescription}>
      <div className={styles.description}>
        <div>
          <p className={styles.title}>付款設定說明</p>
          <p>
            感謝您選用配客嘉循環包裝，若收到貨後<strong>超過14天未歸還</strong>
            ，系統將會自動扣取 NTD:50 循環服務費用。若您被扣款，只要在
            <strong>14天內聯繫客服，並歸還包裝</strong>，即可以退款。
          </p>
        </div>

        <PaymentSVG />
      </div>

      <div className={styles.footer}>
        <SubmitButton
          text="確認"
          onClick={() => {
            navigate('link');
          }}
        />
      </div>
    </div>
  );
};

export default Description;
