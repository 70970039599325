import styles from './index.module.scss';
import { ReactComponent as ArrowRightSVG } from 'assets/icon-arrow-right.svg';
import React, { FC } from 'react';

interface SubmitButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

const SubmitButton: FC<SubmitButtonProps> = (props) => {
  const { text, className, ...restProps } = props;
  return (
    <button className={`${styles.button} ${className ? className : ''}`} {...restProps}>
      <span>{text}</span>
      <ArrowRightSVG />
    </button>
  );
};

export default SubmitButton;
