import { useState } from 'react';
import { useRef } from 'react'; // 21.12.03 SCH
// import { useNavigate } from 'react-router-dom';
import TextField from 'components/TextField';
import styles from './index.module.scss';
import Dialog from 'components/Dialog';
import BackgroundImg from 'assets/home-img.jpg'; // 22.11.30 SCH
import SubmitButton from 'components/SubmitButton';
import { useLiff } from 'context/Liff'; // 21.12.03 SCH
import { postRegister } from 'api';
import { useAuth } from 'context/Auth';
import { useNavigate } from 'react-router-dom';

type FormData = {
  mobile_number?: string;
  mobile_confirmation?: string;
  email?: string;
};
type FormErrorData = {
  mobile_number?: boolean | string;
  mobile_confirmation?: boolean | string;
  email?: boolean | string;
};
type FormName = 'mobile_number' | 'mobile_confirmation' | 'email';

const Register = () => {
  const [visible, setVisible] = useState<boolean>(false); // 22.11.15 SCH
  // const [formData, setFormData] = useState<FormData>({});
  const navigate = useNavigate();
  const { idToken, liff } = useLiff(); // 21.12.03 SCH
  const { auth } = useAuth();
  const [registering, setRegistering] = useState<boolean>(false);
  const [registerError, setRegisterError] = useState<string[]>();
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<FormData>({
    mobile_number: '',
    mobile_confirmation: '',
    email: '',
  });
  const [formError, setFormError] = useState<FormErrorData>({
    mobile_number: false,
    mobile_confirmation: false,
    email: false,
  });

  const onFormChange = async (name: FormName, value: any) => {
    const tmp: FormData = { ...formData };
    tmp[name] = value;
    setFormData(tmp);
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const results = Object.keys(formData).map((key) =>
      validateForm(key, formData[key])
    );
    if (idToken && results.every((result) => result)) {
      registerMember();
    }
  };

  const registerMember = async () => {
    try {
      setRegistering(true);
      console.log('idToken : ' + idToken);
      if (idToken) {
        const { data: resp, error } = await postRegister(idToken, 1, {
          mobile_number: formData.mobile_number,
        });
        setRegistering(false);
        const errorMessages: string[] = [];
        console.log(resp, error);
        if (resp) {
          const { data } = resp;
          if (data.message === 'Successfully created.') {
            setRegisterError(undefined);
          } else {
            errorMessages.push(data.message);
            setRegisterError([data.message]); // 22.07.10 SCH
            console.log(data); // 22.07.10 SCH
          }
        }
        if (error) {
          if (error.response) {
            console.log(error.response); // 22.11.15 SCH
            const { data } = error.response;
            if (data.errors) {
              console.log(data.errors); // 22.11.15 SCH
              if (data.errors.mobile_number) {
                if (
                  data.errors.mobile_number.indexOf(
                    'The user mobile has already been taken.'
                  ) !== -1
                ) {
                  errorMessages.push(
                    '這個手機號碼已被使用，請改填其它手機號碼。'
                  );
                } else {
                  errorMessages.push('手機號碼 驗證錯誤，請重新輸入');
                }
              }
              if (data.errors.email) {
                if (
                  data.errors.user_email.indexOf(
                    'The user email has already been taken.'
                  ) !== -1
                ) {
                  errorMessages.push('這個 Email 已被使用，請改填其它 Email。');
                } else {
                  errorMessages.push('Email 驗證錯誤，請重新輸入');
                }
              }
            } else {
              if (
                data.message === 'The user already exists' ||
                data.message === 'The user line id already exists'
              ) {
                errorMessages.push('這個 LINE 帳號已註冊過會員。');
              } else if (data.message === 'The user mobile already exists') {
                errorMessages.push(
                  '這個手機號碼已被使用，請改填其它手機號碼。'
                );
              } else {
                errorMessages.push('註冊失敗，請重試');
              }
            }
          } else {
            errorMessages.push('註冊失敗，請重試');
          }
          setRegisterError(errorMessages);
        } else {
          setRegistering(true); // 22.02.10 SCH
          console.log(registering);
        }

        setVisible(true);
      }
    } catch (error) {
      setRegistering(false);
      setRegisterError(['註冊失敗，請重試']);
      setVisible(true);
    }
    // 22.07.10 SCH, Updated ...
    if (registerError && registerError.length > 0) {
      console.log(registerError);
    } else {
      console.log('register is success');
    }
  };
  const validateForm = async (name: FormName, value: any) => {
    switch (name) {
      case 'mobile_number':
        if (value === '' || !value) {
          setFormError((prevState) => ({
            ...prevState,
            mobile_number: '請輸入手機號碼',
          }));
          return false;
        }
        const mobileRegex = /^09\d{8}$/;

        if (!mobileRegex.test(value)) {
          setFormError((prevState) => ({
            ...prevState,
            mobile_number: '手機號碼格式錯誤',
          }));
          return false;
        } else {
          setFormError((prevState) => ({ ...prevState, mobile_number: false }));
        }
        return true;

      case 'mobile_confirmation':
        if (value === '' || !value) {
          setFormError((prevState) => ({
            ...prevState,
            mobile_confirmation: '請輸入手機號碼',
          }));
          return false;
        }
        if (value !== formData.mobile_number) {
          setFormError((prevState) => ({
            ...prevState,
            mobile_confirmation: '兩次手機號碼不符合',
          }));
          return false;
        } else {
          setFormError((prevState) => ({
            ...prevState,
            mobile_confirmation: false,
          }));
        }
        return true;

      case 'email':
        if (value === '' || !value) {
          setFormError((prevState) => ({
            ...prevState,
            email: '請輸入 E-mail',
          }));
          return false;
        }
        const emailRegex = /^[a-zA-Z0-9._%-]+@[[a-zA-Z0-9.-@]+\.[a-zA-Z]{2,4}$/;

        if (!emailRegex.test(value)) {
          setFormError((prevState) => ({
            ...prevState,
            email: 'E-mail 格式錯誤',
          }));
          return false;
        } else {
          setFormError((prevState) => ({ ...prevState, email: false }));
        }
        return true;

      default:
        return true;
    }
  };

  // 21.12.03 SCH
  // 原生 submit event上 又再 dispatch一次 submit 無意義
  const onSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    );

    const results = Object.keys(formData).map((key) =>
      validateForm(key, formData[key])
    );
    if (idToken && results.every((result) => result)) {
      registerMember();
    }
  };
  // check auth test
  const onConfirm = () => {
    auth(); // Check Auth Status After Register Success
    // navigate('/terms');

    if (registerError && registerError.length > 0) {
      setVisible(false);
      return;
    }

    setVisible(false);
    // navigate('/payment'); // 22.11.15 SCH
  };
  const onCancel = () => {
    setVisible(false);
    if (registerError && registerError.length > 0) {
      return;
    }

    // Check Auth Status After Register Failed
    auth();
    // liff.closeWindow(); // 22.11.15 SCH
  };

  const isDisabled = () => {
    console.log(formData);
    if (!formData.mobile_number || !formData.mobile_confirmation) {
      return true;
    }
    if (formData.mobile_number !== formData.mobile_confirmation) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.register}>
      <div className={styles.backgroundImg}>
        <img src={BackgroundImg} alt="" />
      </div>
      <div className={styles.registerForm}>
        <h5>歡迎使用</h5>
        <form noValidate autoComplete="off" onSubmit={onFormSubmit}>
          <div>
            <div className={styles.form}>
              <div>
                <TextField
                  type="tel"
                  label="手機號碼"
                  name="mobile_number"
                  onChange={(event) =>
                    onFormChange('mobile_number', event.target.value)
                  }
                />
              </div>
              <div>
                <TextField
                  error={formError.mobile_confirmation ? true : false}
                  helperText={formError.mobile_confirmation || ''}
                  type="tel"
                  label="請再次輸入手機號碼"
                  name="mobile_confirmation"
                  onChange={(event) =>
                    onFormChange('mobile_confirmation', event.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <SubmitButton type="submit" disabled={isDisabled()} text="註冊" />
          <p className={styles.privacy}>
            點擊註冊即代表您同意{' '}
            <a
              href="https://www.packageplus-tw.com/about/terms"
              target="_blank"
              rel="noreferrer"
            >
              使用條款與隱私權政策
            </a>
          </p>
        </form>
      </div>

      {/* <div className={`${styles.footer} ${registering ? styles.loading : ''}`}>
        {registering && (
          <div className={styles.loading}>
            <CircularProgress size={30} />
          </div>
        )}
        <button
          className={registering ? styles.loading : ''}
          disabled={registering}
          onClick={() => onSubmit()}
        >
          註冊
        </button>
      </div> */}

      <Dialog
        visible={visible}
        onConfirm={onConfirm}
        onCancel={onCancel}
        message={
          <div className={styles.dialogHeader}>
            <h5>
              {registerError && registerError.length > 0
                ? '註冊失敗'
                : '註冊成功'}
            </h5>
            {!registerError ? <p>請設定付款方式</p> : <p>{registerError[0]}</p>}
          </div>
        }
        cancelText={'下次再說'}
        // okText={registerError && registerError.length > 0 ? '確定' : '立即設定'}
        okText={registerError && registerError.length > 0 ? '確定' : '知道了'}
        cancelable={!registerError || registerError.length <= 0 || true}
      />
    </div>
  );
};

export default Register;
