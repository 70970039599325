import { vipAxios, Response } from './index';
// import { AxiosError } from 'axios';

export interface LinePay1ResData {
  info: {
    paymentAccessToken: string,
    paymentUrl: {
      app: string,
      web: string,
    }
    transactionId: string,
  }
  returnCode: string,
  returnMessage: string,
}

type LinePayFormData = {
  line_id?: string;
  mobile_number?: string;
  currency?: string;
  amount?: number;
};

// 22.10.11 SCH 綁定 LINE pay
 export const connectLine = async (
  lineToken: string,
  brandID: number,
  formData: LinePayFormData
): Promise<Response<LinePay1ResData>> => {
  try {
    const { data } = await vipAxios.request<LinePay1ResData>({
      url: `/api/member/linepay/payment1-reserve`,
      method: 'POST',
      data: {
        ...formData,
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data: data };

  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

// 22.11.01 SCH
export interface LinePay2Expires {
  returnCode: string,
}

// 22.11.01 SCH 解綁定（使過期而失效） LINE pay
export const expiresLine = async (
  lineToken: string,
  brandID: number,
  formData: LinePayFormData
): Promise<Response<LinePay2Expires>> => {
  try {
    const { data } = await vipAxios.request<LinePay2Expires>({
      url: `/api/member/linepay/line-pay-expire`,
      method: 'POST',
      data: {
        ...formData,
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data: data };

  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
