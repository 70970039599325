import { useState, useRef, useEffect, useContext} from 'react';
import {ReactComponent as AvatarSVG} from 'assets/icon-avatar.svg';
import {ReactComponent as EditSVG} from 'assets/icon-edit.svg';
import TextField from 'components/TextField';
import DatePicker from 'components/DatePicker';
import styles from './profile.module.scss';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'context/Auth';
import {getMe, modifyMe} from "../../api/me"; // 22.04.18 SCH
import {format as dateFormat} from 'date-fns';  // 22.07.07 SCH
// 22.04.19 SCH, Add storage4user['meUserStorage']
import {GlobalContext4User} from '../../storage/user';

export type FormProps = {
  id: number;
  brand_id: number;
  realname: string;
  nickname: string;
  mobile_number: string;
  created_at: string | null;
  line_id: string | null;
  birthday: string | '';
  email: string | null;
  photo_url?: string;
  image?: [
    {
      originFileObj?: Blob;
      uid: number;
      url: string;
      status: string;
      name?: string;
    },
  ];
};
type FormName = 'realname' | 'nickname' | 'birthday' | 'email' | 'photo_url' | 'image';
type FormErrorData = {
  realname?: boolean | string;
  nickname?: boolean | string;
  birthday?: boolean | string;
  email?: boolean | string;
  photo_url?: boolean | string;
};
type PriviewState = {
  previewImage?: string | Blob; // 22.07.11 SCH, Add [ | Blob]
  previewVisible: boolean;
  previewTitle?: string;
};

const Profile = () => {
  const {accessToken, me} = useAuth(); // 22.04.18 SCH
  // @ts-ignore // 22.04.19 SCH
  const {meUserStorage} = useContext(GlobalContext4User);
  const {user, setUser} = meUserStorage;
  const navigate = useNavigate();
  const [updateError, setUpdateError] = useState<string[]>();
  const [formData, setFormData] = useState<FormProps>({
    id: 0,
    brand_id: 0,
    realname: '',
    nickname: '',
    mobile_number: '',
    created_at: null,
    line_id: null,
    birthday: '',
    email: null,
    photo_url: '',
    image: [
      {
        originFileObj: new Blob(),
        uid: 0,
        url: '',
        status: '',
        name: '',
      },
    ],
  });
  const [formError, setFormError] = useState<FormErrorData>({
    realname: false,
    nickname: false,
    birthday: false,
    email: false,
    photo_url: false,
  });

  const ref_first = useRef<boolean>(true); // 22.04.19 SCH
  const updateMessage = useRef<string>('');
  const [disable, setDisable] = useState<boolean>(false); // 22.07.07 SCH
  const [cancel, setCancel] = useState<string>('取消'); // 22.07.07 SCH
  const [imgUrl, setImgUrl] = useState<string>(''); // 22.07.07 SCH
  const imgPreUrl = 'https://packageplusmainsys.s3-ap-northeast-1.amazonaws.com/';

  const onFormChange = async (name: FormName, value: any) => {
    const tmp: FormProps = {...formData};
    if (name === 'birthday') { // 22.07.07 SCH
      tmp[name] = dateFormat(value, 'yyyy-MM-dd');
      // console.log("onFormChange", name, " : ", dateFormat(value, 'yyyy-MM-dd'));
      setFormData(tmp);
      validateForm(name, dateFormat(value, 'yyyy-MM-dd'));
    } else {
      tmp[name] = value;
      // console.log("onFormChange", name, " : ", value); // 22.04.19 SCH
      setFormData(tmp);
      validateForm(name, value);
    }
  };

  // 不能使用 (e: typeof Event), // 22.04.21 SCH
  const handleInput = (e: any) => {
    localStorage.setItem(e.target.name, e.target.value);
  };
  // 22.04.21 SCH (Date 是傳 value 而不是傳 event) !!
  const handleDateInput = (value: any) => {
    // console.log("handleDateInput", "birthday", " : ", dateFormat(value, 'yyyy-MM-dd'));
    // 要在 saveMe() 時才能執行 setUser() // 22.04.21 SCH
    setUser({...user, "birthday": dateFormat(value, 'yyyy-MM-dd')});
    localStorage.setItem("birthday", dateFormat(value, 'yyyy-MM-dd'));
  };

  const validateForm = (name: FormName, value: any) => {
    switch (name) {
      case 'realname':
        if (value === '' || !value) {
          setFormError((prevState) => ({...prevState, realname: '請輸入真實姓名'}));
          return false;
        }
        setFormError((prevState) => ({...prevState, realname: false}));
        return true;
      case 'nickname':
        if (value === '' || !value) {
          setFormError((prevState) => ({...prevState, nickname: '請輸入匿稱'}));
          return false;
        }
        setFormError((prevState) => ({...prevState, nickname: false}));
        return true;
      case 'birthday':
        if (value === '' || !value) {
          setFormError((prevState) => ({...prevState, birthday: '請輸入生日'}));
          return false;
        }
        setFormError((prevState) => ({...prevState, birthday: false}));
        return true;
      case 'email':
        if (value === '' || !value) {
          setFormError((prevState) => ({...prevState, email: '請輸入E-mail'}));
          return false;
        }
        setFormError((prevState) => ({...prevState, email: false}));
        return true;
      default:
        console.log(formError);
        return true;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchMe = async () => {
    await getMe(accessToken!).then(resp => {
          console.log('getMe.resp'); // 22.04.19 SCH
          console.log(resp); // 22.04.19 SCH, OK
          // 22.04.19 SCH
          console.log('user.realname', user.realname); // undefined
          setFormData({
            ...formData,
            realname: resp?.data?.realname!,
            nickname: resp?.data?.nickname!,
            birthday: resp?.data?.birthday!,
            email: resp?.data?.email!, // 22.07.11 SCH
            photo_url: resp?.data?.photo_url!,
          })
          // 22.07.11 SCH, 處理 imgUrl 內容!!
          if (user.photo_url && user.photo_url.length > 9) {
            if (user.photo_url.indexOf('http') < 0) {
              setImgUrl(imgPreUrl + user.photo_url);
            } else {
              setImgUrl(user.photo_url);
            }
          }
        }
    );
  }

  // 設置 web-page.lifecycle // 22.04.18 SCH
  useEffect(() => {
    // componentDidMount 及 componentDidUpdate
    if (ref_first.current) {
      ref_first.current = false;
      fetchMe();
    }
    // componentDidUpdate 及 componentWillUnmount
    return (() => {
      // console.log(`Before Update: State ${user.realname}`);
    })
  }, [fetchMe]);

  const saveMe = async (formData: FormProps) => {
    updateMessage.current = '';
    try {
      if (formData) {
        let image: string | Blob;
        if (formData.image && formData.image.length > 0) {
          const formImage = formData.image[0] as {
            url: string;
            originFileObj: Blob;
            status: string;
          };
          if (formImage) {
            if (formImage.originFileObj && formImage.status === 'done') {
              image = formImage.originFileObj;
            }
          }
          console.log('image.length =', formData.image.length);
          console.log(selectedFile);
          image = selectedFile!;
        }
        const tmpFormData = {...formData};
        console.log(tmpFormData); // 22.07.11 SCH

        const postFormData = new FormData();
        Object.keys(tmpFormData).forEach((key) => {
          if (key === 'image') {
            console.log('image ... '); // 22.07.11 SCH
            console.log(image); // 22.07.11 SCH
            if (image) {
              postFormData.append(key, image);
            } else if (formData.image && formData.image?.length <= 0) {
              // postFormData.append('reset_image', '1');
            }
          } else {
            postFormData.append(key, `${tmpFormData[key]}`);
          }
        });

        postFormData.append('_method', 'PATCH');
        const { data: resp } = await modifyMe(accessToken!, postFormData);
        if (resp) {
          console.log(resp); // 22.04.18 SCH
          const { data } = resp;
          if (data.message === 'Successfully updated.') {
            setUpdateError(undefined);
            formData.photo_url = data.photo_url; // 22.07.11 SCH
            setUser(formData); // 22.04.21 SCH
            updateMessage.current = 'Profile successfully updated.';
            setCancel('返回') // 22.07.07 SCH
            setDisable(true) // 22.07.07 SCH
          } else {
            setUpdateError(['更新失敗，請重試']);
            updateMessage.current = 'profile update is failure ...';
          }
        }
      }
    } catch (error) {
      console.log(error); // 22.04.18 SCH
      setUpdateError(['更新失敗，請重試']);
      updateMessage.current = 'profile update is failure ...';
    }

    console.log(updateMessage.current); // 22.07.11 SCH
    document.getElementById("AlertMsg")!.innerHTML = updateMessage.current;
  }

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileInput = (e: any) => {
    setSelectedFile(e.target.files[0]);
  }

  return (
    <div className={styles.profile}>
      <div className={styles.avatar}>
        { imgUrl.length <= 9 ? <AvatarSVG /> : <img src={ imgUrl } height="80" alt="Image.Url" /> }
        <EditSVG className={styles.edit} />
        <div style={{textAlign:"center"}}>
          <h3>{me?.mobile_number}</h3>
        </div>
      </div>
      <div style={{fontSize:"15px"}}>
        <input type="file" onChange={handleFileInput}/>
      </div>
      <div className={styles.formContainer} id='myContainer'>
        <div className={styles.inner}>
          <TextField
            required
            id="realName"
            label="真實姓名"
            name="realname"
            defaultValue={ user.realname }
            onChange={(event) =>
              {
                onFormChange('realname', event.target.value)
                handleInput(event)
              }
            }
          />
          <TextField
            id="nickName"
            label="暱稱"
            name="nickname"
            defaultValue={ user.nickname }
            onChange={(event) =>
              {
                onFormChange('nickname', event.target.value)
                handleInput(event)
              }
            }
          />
          <DatePicker
              error={false}
              label="生日"
              renderInput={(props) => <TextField {...props} variant="standard" />}
              value={user.birthday} // 22.04.18 SCH, 要放在 renderInput 的下面
              onChange={(newValue) =>
                {
                  console.log(newValue)
                  onFormChange('birthday', newValue)
                  handleDateInput(newValue)
                }
              }
          />
          <TextField
            id="email"
            type="email"
            label="E-mail"
            name="email"
            defaultValue={ user.email }
            onChange={(event) =>
              {
                 onFormChange('email', event.target.value)
                 handleInput(event)
              }
            }
          />
        </div>
        <div className={styles.footer}>
          <button className={styles.save}
            onClick={() => {
              saveMe(formData);
            }}
          disabled={disable}>
            儲存
          </button>
          <button
            id="cancelButton"
            className={styles.cancel}
            onClick={() => {
              navigate('/member');
            }}
          >
            { cancel }
          </button>
          <label id='AlertMsg'>
            { updateMessage.current }
          </label>
        </div>
      </div>
    </div>
  );
};
export default Profile;
