import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import styled from '@mui/material/styles/styled';
import { TextField } from '@mui/material';
import styles from './emailDialog.module.scss';
import { useState } from 'react';
import { ReactComponent as ArrowRightSVG } from 'assets/icon-arrow-right.svg';
import { useNavigate } from 'react-router-dom';

const DialogTitle = styled(MuiDialogTitle)(() => ({
  fontSize: 15,
  fontWeight: 'bold',
}));

const DialogContent = styled(MuiDialogContent)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 28px',
  flexDirection: 'column',
}));

const DialogActions = styled(MuiDialogActions)(() => ({
  padding: '0 27px',
  paddingBottom: '21px',
}));

const EmailDialog = ({
  open,
  onBackdropClick,
}: {
  open: boolean;
  onBackdropClick: () => void;
}) => {
  const [accept, setAccept] = useState(true);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const isDisabled = () => {
    const emailRegex = /^[a-zA-Z0-9._%-]+@[[a-zA-Z0-9.-@]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(email)) {
      return true;
    }
    return false;
  };

  const onLink = () => {
    // console.log(email, accept);
    // TODO: link Line pay here;

    // demo only, result page should be redirected from line pay link page
    navigate('result');
  };

  return (
    <Dialog
      open={open}
      onBackdropClick={onBackdropClick}
      PaperProps={{
        sx: {
          width: 225,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>請輸入電子信箱</DialogTitle>
      <DialogContent>
        <TextField
          placeholder="example@gmail.com"
          sx={{
            '& .MuiInput-root': {
              padding: 0,
            },
            '& .MuiInput-input': {
              fontSize: 13,
              textAlign: 'center',
            },
            '& .MuiFormHelperText-root': {
              fontSize: 10,
              textAlign: 'center',
            },
          }}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          value={email}
          variant="standard"
          helperText="為日後寄送發票，請填寫電子信箱。"
        />

        <div className={styles.adEmail}>
          <input
            type="checkbox"
            id="adEmail"
            checked={accept}
            onChange={(event) => {
              setAccept(event.target.checked);
            }}
          />
          <label htmlFor="adEmail">
            勾選表示您同意配客嘉透過此郵件寄送優惠訊息、最新消息。
          </label>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          className={styles.button}
          disabled={isDisabled()}
          onClick={onLink}
        >
          <span>確認</span>
          <ArrowRightSVG />
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailDialog;
