import {useState, useEffect, useRef} from 'react';
import { ReactComponent as ArrowSVG } from 'assets/icon-arrow-right.svg';
import { ReactComponent as InfoSVG } from 'assets/icon-info.svg';
import { ReactComponent as SortSVG } from 'assets/icon-sort.svg';
import { Link, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import { format } from 'date-fns'; // 21.12.16 SCH
import { useAuth } from 'context/Auth'; // 22.01.08 SCH
import { listUserCoupons, UserCouponsData } from 'api/logs';

// const Mock = [
//   {
//     id: 1,
//     title: 'OKO商城 $30折價券',
//     date: '2021/11/11~2022/03/03',
//     type: 'code',
//     code: 'JE9Z0016HA3N',
//   },
//   {
//     id: 2,
//     title: 'OKO商城 $40折價券',
//     date: '2021/11/11~2022/04/04',
//     type: 'coupon',
//   },
//   {
//     id: 3,
//     title: 'OKO商城 $50折價券',
//     date: '2021/11/11~2022/05/05',
//     type: 'coupon',
//   },
// ];

const OnlineDiscount = () => {
  const [filter, setFilter] = useState<'all' | 'useable' | 'expired'>('all');
  const [sort, setSort] = useState<'ascend' | 'descend'>('descend');
  const { accessToken, me } = useAuth(); // 21.12.15 SCH
  const [coupons, setCoupons] = useState<UserCouponsData[]>();
  const { pathname } = useLocation();
  const filterRef = useRef<string>('all'); // 22.05.29 SCH
  const sortRef = useRef<string>('descend'); // 22.05.29 SCH

  useEffect(() => {
    fetchCoupons();
    // eslint-disable-next-line
  }, [pathname]);

  const fetchCoupons = async () => {
    try {
      // 22.05.29 SCH, add [ filterRef.current, sortRef.current ]
      const { data } = await listUserCoupons(accessToken!, 1, me?.id!,
        filterRef.current, sortRef.current);
      console.log(data);
      if (!data) {
        return 'Have not any Coupons !!';
      } else {
        setCoupons(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateStr: string) => {
    if (dateStr.indexOf('T') > 0) {
      let newDate = format(new Date(dateStr), 'yyyy-MM-dd HH:mm:ss');
      return newDate; // 22.05.29 SCH
    }
    return dateStr;
  };

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <button
            className={filter === 'all' ? styles.active : undefined}
            onClick={() => {
              setFilter('all');
              filterRef.current = 'all';
              fetchCoupons();
            }}
          >
            全部
          </button>
          <button
            className={filter === 'useable' ? styles.active : undefined}
            onClick={() => {
              setFilter('useable');
              filterRef.current = 'useable';
              fetchCoupons();
            }}
          >
            可使用
          </button>
          <button
            className={filter === 'expired' ? styles.active : undefined}
            onClick={() => {
              setFilter('expired');
              filterRef.current = 'expired';
              fetchCoupons();
            }}
          >
            已逾期
          </button>
        </div>
        <div className={styles.sort}>
          <button
            onClick={() => {
              setSort(sort === 'ascend' ? 'descend' : 'ascend');
              sortRef.current = sort === 'ascend' ? 'descend' : 'ascend';
              fetchCoupons();
            }}
          >
            {sort === 'descend' ? '最新' : '最舊'}
            <SortSVG />
          </button>
        </div>
      </div>
      <div className={styles.list}>
        {coupons?.map((item) => (
          <div key={item.id} className={styles.listItem}>
            <div className={styles.shadow}></div>
            <img src={item.picture_url} alt="" className={styles.img} />
            <div className={styles.info}>
              <h5>{item.title}</h5>
              <div className={styles.date}>
                <span>
                  {item.start_at.slice(0,10)} ~ {item.expired_at.slice(0,10)}
                </span>
              </div>
              {item.coupon_id === 0 ? (
                <span className={styles.code}>{item.description}</span>
              ) : (
                <div>
                  <span>{formatDate(item.created_at)} - </span>
                  <button>
                    <span>選券</span>
                    <ArrowSVG />
                  </button>
                </div>
              )}
            </div>
            <Link to="./detail" className={styles.infoBtn}>
              <InfoSVG />
            </Link>
          </div>
        ))}
      </div>
    </>
  );
  // return (
  //   <div className={styles.list}>
  //     {Mock?.map((item) => (
  //       <div key={item.id} className={styles.listItem}>
  //         <div className={styles.shadow}></div>
  //         <div className={styles.img}></div>
  //         <div className={styles.info}>
  //           <h5>{item.title}</h5>
  //           <span className={styles.date}>{item.date}</span>
  //           {item.type === 'code' ? (
  //             <span className={styles.code}>{item.code}</span>
  //           ) : (
  //             <button>
  //               <span>選券</span>
  //               <ArrowSVG />
  //             </button>
  //           )}
  //         </div>
  //         <Link to="./detail" className={styles.infoBtn}>
  //           <InfoSVG />
  //         </Link>
  //       </div>
  //     ))}
  //   </div>
  // );
};

export default OnlineDiscount;
