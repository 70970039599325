import Helmet from 'react-helmet';
import styles from './index.module.scss';

const CouponDetail = () => {
  return (
    <>
      <Helmet title="詳情" />
      <div className={styles.couponDetail}>
        <div>
          <p>詳情詳情詳情詳情詳情詳情詳情詳情詳情詳情詳情詳情</p>
        </div>
      </div>
    </>
  );
};
export default CouponDetail;
