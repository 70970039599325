import {
  FC,
  SyntheticEvent,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import styled from '@mui/material/styles/styled';
import styles from './index.module.scss';
import { Autocomplete } from '@mui/material';
import { useAuth } from 'context/Auth';
import { listServicePoints, ServicePointsData } from 'api/query';
import { chkEpkId } from 'api/return'; // 21.12.23 SCH
import { GlobalContext } from '../../storage'; // 21.12.23 SCH

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  marginTop: 10,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginBottom: 20,
}));

const DialogContent = styled(MuiDialogContent)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minWidth: 285,
  padding: '20px',
  paddingBottom: 28,
}));

const DialogActions = styled(MuiDialogActions)(() => ({
  padding: '0 20px',
  paddingBottom: 18,
  display: 'flex',
  flexDirection: 'column',
  '& > :not(:first-of-type)': {
    marginLeft: 0,
    marginTop: 10,
  },
}));

interface ManualModalProps {
  visible: boolean;
  step: number;
  onConfirm: (data?: ServicePointsData | string) => void;
  onClose?: () => void;
}

const ManualModal: FC<ManualModalProps> = (props) => {
  const { visible, onConfirm, step, onClose } = props;
  const { accessToken, me } = useAuth(); // 21.12.10 SCH
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPoint, setSelectedPoint] = useState<ServicePointsData>();
  const [servicePoints, setServicePoints] = useState<ServicePointsData[]>();
  const [packageId, setPackageId] = useState<string>();
  const inputRef = useRef<HTMLInputElement | null>(null); // 21.12.25 SCH
  const [epkIdFound, setEpkIdFound] = useState<boolean>(false);
  const { returnStorage } = useContext(GlobalContext); // 21.12.23 SCH
  const { store_id, setStoreId } = returnStorage; // 21.12.23 SCH
  const { chs_short_code, setChsShortCode } = returnStorage; // 21.12.23 SCH
  const { epk_id, setEpkId } = returnStorage; // 21.12.23 SCH
  const epkId = useRef<string>(); // 21.12.24 SCH

  useEffect(() => {
    // fetchServicePoints();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!visible) {
      onAutoCompleteClose();
      setSelectedPoint(undefined);
    }
  }, [visible]);

  const fetchServicePoints = async () => {
    try {
      setLoading(true);
      const data = await listServicePoints(accessToken!, 1, me?.id!);
      setServicePoints(
        data?.data
          ?.filter(
            (marker) =>
              marker.latitude && marker.longitude && marker.enable === 1
          )
          .map((marker) => {
            let info: ServicePointsData = {
              id: marker.id,
              latitude: marker.latitude,
              longitude: marker.longitude,
              name: '【' + marker.channel_name + '】' + marker.name + '：' + marker.address,
              channel_name: marker.channel_name,
              address: marker.address,
              service_time: marker.service_time,
              enable: marker.enable,
              store_id: marker.store_id, // 21.12.23 SCH
              chs_short_code: marker.chs_short_code, // 21.12.23 SCH
              // TODO: change to Real Data
              return_type: marker.return_type, // 22.05.14 SCH
              discount_type: marker.discount_type, // 22.05.16 SCH
              distance: 0, // 22.05.20 SCH
              visible: 1, // 22.05.20 SCH
            };
            return info;
          })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const findEpkId = async () => {
    try {
      console.log('packageId：' + packageId);
      console.log('epkId：' + epkId.current);
      const { data } = await chkEpkId(accessToken!, 1, {
        epk_id: epkId.current,
      });
      if (data) {
        console.log(data);
        // 21.12.24 SCH, 將資料存入到 storage !!
        console.log('epk_id：' + data?.epk_id);
        setEpkId(data?.epk_id); // 21.12.24 SCH
        setEpkIdFound(true);
      } else {
        setEpkIdFound(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onOpen = async () => {
    setOpen(true);
    setLoading(true);
    await fetchServicePoints();
    setLoading(false);
  };
  const onAutoCompleteClose = () => {
    setServicePoints([]);
    setOpen(false);
  };

  const onSelected = (
    event: SyntheticEvent<Element, Event>,
    value: ServicePointsData | null
  ) => {
    if (value) {
      setSelectedPoint(value);
      console.log(value);
      // 21.12.24 SCH, 將資料存入到 storage !!
      setChsShortCode(value.chs_short_code);
      setStoreId(value.store_id);
      // onConfirm();
    }
  };
  const handleOnConfirm = () => {
    if (step === 0) {
      onConfirm(selectedPoint);
      console.log(chs_short_code);
      console.log(store_id); // 21.12.24 SCH, OK
      return;
    }
    onConfirm(selectedPoint);
    setPackageId(undefined);
    setEpkIdFound(false);
  };

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>
        {step === 0 ? '請下拉選擇歸還點或輸入店家名稱' : '請輸入包裝物品編號'}
      </DialogTitle>
      <DialogContent>
        {step === 0 ? (
          <div className={styles.manualContainer}>
            <Autocomplete
              sx={{
                display: 'flex',
                flex: 1,
                width: '100%',
                alignItems: 'center',
                '& > div': {
                  width: '100%',
                },
                '& input': {
                  width: '100%',
                  outline: 'none',
                  fontSize: 16,
                  border: '1px solid #00000029',
                  height: 58,
                  padding: '0 10px',
                },
              }}
              open={open}
              onOpen={onOpen}
              onClose={onAutoCompleteClose}
              options={servicePoints || []}
              noOptionsText="無歸還點"
              onChange={onSelected}
              loading={loading}
              loadingText="載入中..."
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    type="text"
                    placeholder="搜尋歸還點"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
            {selectedPoint && (
              <div className={styles.selectedInfo}>
                <span>歸還點：{selectedPoint?.name}</span>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.manualContainer}>
            <input
              type="text"
              pattern="[A-Z0-9]*"
              ref={inputRef} // 21.12.25 SCH
              className={styles.manualInput}
              onChange={(event) => {
                setPackageId(event.target.value as any);
                epkId.current = event.target.value;
                if (epkId.current) {
                  if (epkId.current.length >= 6) {
                    findEpkId();
                  }
                }
              }}
            />
            {epkIdFound && (
              <div className={styles.selectedInfo}>
                <span>已找到包裝物品編號：{epk_id}</span>
              </div>
            )}
            {!epkIdFound && (
              <div className={styles.selectedInfo}>
                <span>請輸入循環使用購物袋(包裝箱)編號</span>
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {(selectedPoint || epkIdFound) && (
          <button
            className={`${styles.actionButton}`}
            onClick={handleOnConfirm}
          >
            確定
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ManualModal;
