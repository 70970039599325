import { useEffect, useState } from 'react';
import { ReactComponent as MasterCardSVG } from 'assets/mc_symbol.svg';
import { ReactComponent as PlusSVG } from 'assets/icon-plus.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/icon-arrow-right.svg';
import styles from './index.module.scss';
import { useLocation, useNavigate } from 'react-router';

type PaymentMethod = {
  id: string;
  name: string;
  type: 'add' | 'masterCard';
};

const renderIcon = (type: 'add' | 'masterCard') => {
  switch (type) {
    case 'add':
      return <PlusSVG />;
    case 'masterCard':
      return <MasterCardSVG />;
    default:
      return null;
  }
};

const PaymentList = () => {
  const [method, setMethods] = useState<PaymentMethod[]>([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    if (state && state.mock) {
      setMethods([
        {
          id: 'mastercard',
          name: '···· ···· ···· 8888',
          type: 'masterCard',
        },
      ]);
    }
  }, [state]);
  const handleAddPayment = () => {
    navigate('/payment/setting/add');
  };
  return (
    <div className={styles.paymentList}>
      {[
        ...method,
        {
          id: 'add',
          name: '新增付款方式',
          type: 'add',
        } as PaymentMethod,
      ].map((method) => (
        <div key={method.id}>
          <button
            onClick={() => {
              if (method.id === 'add') {
                handleAddPayment();
              }
            }}
          >
            {renderIcon(method.type)}
            <span>{method.name}</span>
            {method.type !== 'add' && <ArrowRightSVG />}
          </button>
        </div>
      ))}
    </div>
  );
};

export default PaymentList;
