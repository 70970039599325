import { vipAxios, Response } from './index';
// import { AxiosError } from 'axios';


export interface QRCode1ApiData {
  channel_name: string;
  channel_id: number;
  use_type: number; // 21.12.21 SCH
  svpt_name: string;
  svpt_id: number;
  chs_short_code: string; // 22.02.09 SCH
  store_id: string; // 22.02.09 SCH
  return_type: 'box' | 'staff'; // 22.05.29 SCH
}
type qry1FormData = {
  chs_short_code?: string,
  store_id?: string,
  lihi_url?: string,
};
/**
 * 查詢通路名稱(+id)及店家名稱(+id)!!
 * @param lineToken line token
 * @param formData 通路short_code、服務據點store_id、lihi_url資料
 */
export const getChannelStoreBySC = async (
  lineToken: string,
  brandID: number,
  formData: qry1FormData
): Promise<Response<QRCode1ApiData>> => {
  try {
    const { data } = await vipAxios.request<QRCode1ApiData>({
      url: `/api/member/getChannelStoreBySC`,
      method: 'POST',
      data: {
        ...formData,
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data: data };

  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};


export interface QRCode2ApiData {
  type_name: string;
  date_time: string;
}
type qry2FormData = {
  epk_id?: string,
};
/**
 * 查詢環保包材類型名稱!!
 * @param lineToken line token
 * @param formData epk_id 資料
 */
export const getEpkType = async (
  lineToken: string,
  brandID: number,
  formData: qry2FormData
): Promise<Response<QRCode2ApiData>> => {
  try {
    const { data } = await vipAxios.request<QRCode2ApiData>({
      url: `/api/member/getEpkType`,
      method: 'POST',
      data: {
        ...formData,
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data: data };

  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};


export interface ChkEpkIdData {
  id: number;
  epk_id: string;
  type: string;
}
type qry4FormData = {
  epk_id?: string,
};
/**
 * 查詢環保包材類型名稱!!
 * @param lineToken line token
 * @param formData epk_id 資料
 */
export const chkEpkId = async (
  lineToken: string,
  brandID: number,
  formData: qry4FormData
): Promise<Response<ChkEpkIdData>> => {
  try {
    const { data } = await vipAxios.request<ChkEpkIdData>({
      url: `/api/member/chkEpkId`,
      method: 'POST',
      data: {
        ...formData,
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data: data };

  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};


export interface OtherUserData {
  id: number;
  brand_id: number;
  realname: string;
  nickname: string;
  mobile_number: string;
  email: string;
}
type qry3FormData = {
  mobile_number?: string,
};
/**
 * 查詢為他人(循環包裝使用者)還的他人.(UserData)!!
 * @param lineToken line token
 * @param formData 使用者手機號碼
 */
export const chkOtherUser = async (
  lineToken: string,
  brandID: number,
  formData: qry3FormData
): Promise<Response<OtherUserData>> => {
  try {
    const { data } = await vipAxios.request<OtherUserData>({
      url: `/api/member/chkOtherUser`,
      method: 'POST',
      data: {
        ...formData,
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data: data };

  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};


export interface Logs1Message {
  success: boolean;
  message: string;
  userRentLog_id: number;
  user_rent_logs: object;
  svpt_info: string;
  userVoucherLog_id: number;
}

type Logs1FormData = {
  epk_id?: string;
  renter_id?: number; // 21.12.18 SCH
  return_user_id?: number;
  chs_id?: number, // 21.12.15 SCH
  svpt_id?: number,
  return_type?: number;
};
/**
 * 新增使用者租還記錄!!
 * @param lineToken line token
 * @param formData 租還記錄資料
 */
export const saveUserRentLogs = async (
  lineToken: string,
  brandID: number,
  formData: Logs1FormData
): Promise<Response<Logs1Message>> => {
  try {
    const { data } = await vipAxios.request<Logs1Message>({
      url: `/api/member/saveUserRentLogs`,
      method: 'POST',
      data: {
        ...formData,
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data: data };

  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};


export interface Logs2Message {
  success: boolean;
  message: string;
  user_rent_logs: object;
}

type Logs2FormData = {
  id?: number;
  userRentLog_id?: number;
  coupon_id?: number;
};
/**
 * 更新使用者租還記錄!!
 * @param lineToken line token
 * @param formData 租還記錄及優惠券資料
 */
 export const updateUserRentLogs = async (
  lineToken: string,
  brandID: number,
  formData: Logs2FormData
): Promise<Response<Logs2Message>> => {
  try {
    const { data } = await vipAxios.request<Logs2Message>({
      url: `/api/member/updateUserRentLogs`,
      method: 'POST',
      data: {
        ...formData,
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data: data };

  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};


export interface Logs3Message {
  success: boolean;
  message: string;
  userVoucherLog_id: number;
  user_voucher_logs: object;
}

type Logs3FormData = {
  id?: number;
  userVoucherLog_id?: number;
};
/**
 * 更新歸還者兌換券資料!!
 * @param lineToken line token
 * @param formData 歸還者兌換券資料
 */
 export const updateUserVoucherLogs = async (
  lineToken: string,
  brandID: number,
  formData: Logs3FormData
): Promise<Response<Logs3Message>> => {
  try {
    const { data } = await vipAxios.request<Logs3Message>({
      url: `/api/member/updateUserVoucherLogs`,
      method: 'POST',
      data: {
        ...formData,
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${lineToken}`,
      },
    });
    return { data: data };

  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
