import MuiSelect from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import MuiMenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MuiCheckbox from '@mui/material/Checkbox';
import { FC, ReactElement } from 'react';
import { ReactComponent as ArrowDownSVG } from 'assets/icon-arrow-down.svg';
import { Typography, SelectChangeEvent } from '@mui/material';
import styles from './index.module.scss';
import CheckIcon from '@mui/icons-material/Check';

const CustomSelect = styled(MuiSelect)(() => ({
  borderRadius: 27,
  width: '100%',
  height: 40,
  backgroundColor: 'white',

  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
      borderWidth: '1px',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
  },
  '& .MuiSelect-icon': {
    top: 'auto',
  },
}));

const MenuItem = styled(MuiMenuItem)(() => ({
  fontSize: 14,
}));
const Checkbox = styled(MuiCheckbox)(() => ({
  padding: 0,
  marginRight: 6,
  borderRadius: 6,
  borderColor: '#CBCBCB',
}));
interface OptionsItem {
  label: string;
  value: string;
  icon?: ReactElement;
}
interface SelectProps {
  options: OptionsItem[] | OptionsItem[][];
  placeholder: string;
  multiple?: boolean;
  value?: string | string[];
  onChange?: (event: SelectChangeEvent<unknown>) => void;
  menuMaxWidth?: boolean;
}
const Select: FC<SelectProps> = (props) => {
  const { placeholder, options, onChange, multiple, value, menuMaxWidth } =
    props;
  return (
    <CustomSelect
      displayEmpty
      onChange={onChange}
      multiple={multiple}
      value={value}
      renderValue={(value: any) => {
        if (!value) {
          return (
            <Typography sx={{ fontSize: 14 }} color="#999999">
              {placeholder}
            </Typography>
          );
        }
        if (multiple) {
          return (
            <Typography sx={{ fontSize: 14 }} color="#999999">
              {placeholder}
            </Typography>
          );
        }
        return (
          <Typography sx={{ fontSize: 14 }}>
            {(() => {
              if (
                Object.prototype.toString.call(options[0]) ===
                  '[object Array]' ||
                Array.isArray(options[0])
              ) {
                return (options as OptionsItem[][]).filter((option) => {
                  return option.some((subOption) => subOption.value === value);
                })[0]?.[0]?.label;
              }
              return (options as OptionsItem[]).find(
                (option) => option.value === value
              )?.label;
            })()}
          </Typography>
        );
      }}
      IconComponent={ArrowDownSVG}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: menuMaxWidth ? 40 * 4 : undefined,
          },
        },
        MenuListProps: {
          dense: true,
        },
      }}
    >
      {options.map((option, index) => {
        if (
          Object.prototype.toString.call(option) === '[object Array]' ||
          Array.isArray(option)
        ) {
          return (option as OptionsItem[]).map((subOption, subIndex) => {
            if (
              index !== options.length - 1 &&
              subIndex === (option as OptionsItem[]).length - 1
            ) {
              return [
                <MenuItem key={subOption.value} value={subOption.value}>
                  {multiple && (
                    <Checkbox
                      disableRipple
                      icon={<div className={styles.checkbox} />}
                      checkedIcon={
                        <div className={`${styles.checkbox} ${styles.checked}`}>
                          <CheckIcon />
                        </div>
                      }
                      checked={
                        value ? value.indexOf(subOption.value) > -1 : false
                      }
                    />
                  )}
                  {subOption.icon && subOption.icon}
                  {subOption.label}
                </MenuItem>,
                <Divider key={`divider-${index}`} />,
              ];
            }
            return (
              <MenuItem key={subOption.value} value={subOption.value}>
                {multiple && (
                  <Checkbox
                    disableRipple
                    icon={<div className={styles.checkbox} />}
                    checkedIcon={
                      <div className={`${styles.checkbox} ${styles.checked}`}>
                        <CheckIcon />
                      </div>
                    }
                    checked={
                      value ? value.indexOf(subOption.value) > -1 : false
                    }
                  />
                )}
                {subOption.icon && subOption.icon}
                {subOption.label}
              </MenuItem>
            );
          });
        } else {
          if (index === options.length - 1) {
            return (
              <MenuItem key={option.value} value={option.value}>
                {multiple && (
                  <Checkbox
                    disableRipple
                    icon={<div className={styles.checkbox} />}
                    checkedIcon={
                      <div className={`${styles.checkbox} ${styles.checked}`}>
                        <CheckIcon />
                      </div>
                    }
                    checked={value ? value.indexOf(option.value) > -1 : false}
                  />
                )}
                {option.icon && option.icon}
                {option.label}
              </MenuItem>
            );
          }
          return [
            <MenuItem key={option.value} value={option.value}>
              {multiple && (
                <Checkbox
                  disableRipple
                  icon={<div className={styles.checkbox} />}
                  checkedIcon={
                    <div className={`${styles.checkbox} ${styles.checked}`}>
                      <CheckIcon />
                    </div>
                  }
                  checked={value ? value.indexOf(option.value) > -1 : false}
                />
              )}
              {option.icon && option.icon}
              {option.label}
            </MenuItem>,
            <Divider key={`divider-${index}`} />,
          ];
        }
      })}
    </CustomSelect>
  );
};

export default Select;
