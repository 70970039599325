import { useState, useRef, useEffect } from 'react';
// import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import Dialog from 'components/Dialog';
import { ReactComponent as ArrowSVG } from 'assets/icon-arrow-right.svg';
import { ReactComponent as InfoSVG } from 'assets/icon-info.svg';
import { ReactComponent as SortSVG } from 'assets/icon-sort.svg';
import TextField from 'components/TextField';
import styles from './index.module.scss';
import { format } from 'date-fns'; // 21.12.16 SCH
import { useAuth } from 'context/Auth'; // 22.01.08 SCH
import { listUserVoucherLogs, UserVoucherLogsData } from 'api/logs';
import { updateUserVoucherLogs } from 'api/return';
// import { ListItem } from '@mui/material';

type DiscountData = {
  id: number;
  svpt_info: string;
  created_at: string;
  use_type?: number; // 21.12.22 SCH
  redeem_code?: string;
  giftcard_code?: string;
  redeem_url?: string;
  pin_code?: string;
  used?: boolean; // 21.12.29 SCH
  picture_url?: string; // 22.05.29 SCH
};

type FormData = {
  auth_num?: string;
};
type FormName = 'auth_num'; // 22.01.19 SCH

const Mock: UserVoucherLogsData[] = [
  {
    id: 1,
    userVoucherLog_id: 1,
    user_rent_log_id: 1,
    return_user_id: 1,
    chs_id: 1,
    svpt_id: 1,
    use_type: 1,
    svpt_info: '7-11.CityCafe(中)一杯 das dsad asdadasasd sss',
    created_at: '2021/11/11~2022/03/03',
    redeem_code: 'SETDKQNSEZFG',
    giftcard_code: 'asdasd',
    redeem_url: 'https://giftcard.carrefour.com.tw/giftcard/84460132PE',
    pin_code: '9797',
    used: false,
    picture_url: '',
  },
  {
    id: 2,
    userVoucherLog_id: 1,
    user_rent_log_id: 1,
    return_user_id: 1,
    chs_id: 1,
    svpt_id: 1,
    use_type: 1,
    svpt_info: '家樂福$30電子禮物卡',
    created_at: '2021/11/11~2022/04/04',
    redeem_code: 'SETDKQNSEZFG',
    giftcard_code: 'asdasd',
    redeem_url: 'https://giftcard.carrefour.com.tw/giftcard/84460132PE',
    pin_code: '9797',
    used: false,
    picture_url: '',
  },
  {
    id: 3,
    svpt_info: '里仁門市歸還小禮物',
    created_at: '2021/11/11~2022/05/05',
    userVoucherLog_id: 1,
    user_rent_log_id: 1,
    return_user_id: 1,
    chs_id: 1,
    svpt_id: 1,
    use_type: 1,
    redeem_code: 'SETDKQNSEZFG',
    giftcard_code: 'asdasd',
    redeem_url: 'https://giftcard.carrefour.com.tw/giftcard/84460132PE',
    pin_code: '9797',
    used: true,
    picture_url: '',
  },
];

const OfflineDiscount = () => {
  const [filter, setFilter] = useState<'all' | 'useable' | 'expired'>('all');
  const [sort, setSort] = useState<'ascend' | 'descend'>('descend');
  const filterRef = useRef<string>('all'); // 22.05.29 SCH
  const sortRef = useRef<string>('descend'); // 22.05.29 SCH
  const [redeemVisible, setRedeemVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { accessToken, me } = useAuth(); // 21.12.19 SCH
  const [userVoucherLogs, setUserVoucherLogs] =
    useState<UserVoucherLogsData[]>();
  const userVoucherLog_id = useRef<number>(0); // 21.12.20 SCH
  const useType = useRef<number>(); // 22.01.19 SCH
  const redeemUrl = useRef<string>(); // 22.01.19 SCH
  const buttonLabel = useRef<string>(); // 22.01.19 SCH
  const redeemCode = useRef<string>(); // 22.01.20 SCH
  const authNum = useRef<number>(); // 22.01.19 SCH
  const [formData, setFormData] = useState<FormData>({
    auth_num: '',
  });

  const refreshPage = () => {
    window.location.reload(); // 21.12.20 Mike
  };
  useEffect(() => {
    fetchUserVoucherLogs(); // 21.12.19 SCH
    // eslint-disable-next-line
  }, [pathname]);

  const fetchUserVoucherLogs = async () => {
    try {
      // 22.05.29 SCH, add [ filterRef.current, sortRef.current ]
      const { data } = await listUserVoucherLogs(accessToken!, 1, me?.id!,
        filterRef.current, sortRef.current);
      console.log(data);
      if (!data) {
        return 'Have not any Vouchers !!';
      } else {
        // FIXME: change back to data for display real data from server
        setUserVoucherLogs(data); // 22.05.14 SCH
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 22.05.30 SCH, vip 2.0 已挪到(discountInfo.tsx)使用!!
  const changeUserVoucherLogs = async () => {
    try {
      const { data } = await updateUserVoucherLogs(accessToken!, 1, {
        id: userVoucherLog_id.current,
        userVoucherLog_id: userVoucherLog_id.current,
      });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
    refreshPage(); // 21.12.20 Mike
  };

  const formatDate = (dateStr: string) => {
    if (dateStr.indexOf('T') > 0) {
      let newDate = format(new Date(dateStr), 'yyyy-MM-dd HH:mm:ss');
      return newDate; // 22.05.29 SCH
    }
    return dateStr;
  };

  const onFormChange = async (name: FormName, value: any) => {
    const tmp: FormData = { ...formData };
    tmp[name] = value;
    setFormData(tmp);
  };

  const isEnabled = () => {
    if (!formData.auth_num) {
      return false;
    }
    if (formData.auth_num !== authNum.current?.toString()) {
      return false;
    }
    return true;
  };

  const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // 22.05.30 SCH, vip 2.0 已不再使用!!
  const setRedeem = (type: number, id: number, label: string, url: string) => {
    useType.current = type;
    userVoucherLog_id.current = id;
    buttonLabel.current = label;
    redeemUrl.current = url;
    authNum.current = getRandomInt(1000, 9999);
    setRedeemVisible(true); // 22.01.20 SCH
  };

  const onConfirm = () => {
    if (formData.auth_num !== authNum.current?.toString()) {
      return false;
    }
    setRedeemVisible(false);
    // 22.03.25 SCH
    switch (useType.current) {
      case 1:
        changeUserVoucherLogs();
        break;
      case 2:
        changeUserVoucherLogs();
        window.open(redeemUrl.current);
        break;
      case 3:
        changeUserVoucherLogs();
        break;
      case 4:
        changeUserVoucherLogs();
        window.open(redeemUrl.current);
        break;
    }
    // navigate('/payment');
  };
  const onCancel = () => {
    setRedeemVisible(false);
  };

  // 22.05.30 SCH, vip 2.0 已不再使用!!
  const renderMethod = (item: DiscountData) => {
    switch (item.use_type) {
      case 1:
        return (
          <div className={styles.btnContainer}>
            <button
              disabled={item.used}
              onClick={() => {
                redeemCode.current = item.redeem_code;
                setRedeem(item.use_type!, item.id, '立即使用', '');
              }}
            >
              立即使用
            </button>
            <span className={styles.code}>{item.redeem_code}</span>
          </div>
        );
      case 2:
        return (
          <div className={styles.btnContainer}>
            <button
              disabled={item.used}
              onClick={() => {
                redeemCode.current = 'pw:' + item.pin_code;
                setRedeem(item.use_type!, item.id, '前往使用', item.redeem_url!);
              }}
            >
              <span>前往使用</span>
              {/* <ArrowSVG /> */}
            </button>
            <div>
              <span>pw:{item.pin_code}</span>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={styles.btnContainer}>
            <button
              disabled={item.used}
              onClick={() => {
                // redeemCode.current = '無優惠代碼可立即兌換';
                setRedeem(item.use_type!, item.id, '兌換', '');
              }}
            >
              兌換
            </button>
          </div>
        );
      case 4:
        return (
          <div className={styles.btnContainer}>
            <button
              disabled={item.used}
              onClick={() => {
                redeemCode.current = 'pw:' + item.pin_code;
                setRedeem(item.use_type!, item.id, '前往使用', item.redeem_url!
                );
              }}
            >
              <span>前往使用</span>
              {/* <ArrowSVG /> */}
            </button>
            <div>
              <span>pw:{item.pin_code}</span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <button
            className={filter === 'all' ? styles.active : undefined}
            onClick={() => {
              setFilter('all');
              filterRef.current = 'all';
              fetchUserVoucherLogs();
            }}
          >
            全部
          </button>
          <button
            className={filter === 'useable' ? styles.active : undefined}
            onClick={() => {
              setFilter('useable');
              filterRef.current = 'useable';
              fetchUserVoucherLogs();
            }}
          >
            可使用
          </button>
          <button
            className={filter === 'expired' ? styles.active : undefined}
            onClick={() => {
              setFilter('expired');
              filterRef.current = 'expired';
              fetchUserVoucherLogs();
            }}
          >
            已逾期
          </button>
        </div>
        <div className={styles.sort}>
          <button
            onClick={() => {
              setSort(sort === 'ascend' ? 'descend' : 'ascend');
              sortRef.current = sort === 'ascend' ? 'descend' : 'ascend';
              fetchUserVoucherLogs();
            }}
          >
            {sort === 'descend' ? '最新' : '最舊'}
            <SortSVG />
          </button>
        </div>
      </div>

      <div className={styles.list}>
        {userVoucherLogs?.map((item) => (
          <div key={item.id} className={styles.listItem}>
            <div className={styles.shadow}></div>
            <img src={item.picture_url} alt="" className={styles.img} />
            <div className={styles.info}>
              <h5>{item.svpt_info}</h5>
              <span className={styles.date}>{formatDate(item.created_at)}</span>
            </div>
            {/* {renderMethod(item)} */}
            <div className={styles.btnContainer}>
              <button
                disabled={item.used}
                onClick={() => {
                  // redeemCode.current = '無優惠代碼可立即兌換';
                  // setRedeem(item.use_type!, item.id, '兌換', '');
                  // 22.05.30 SCH, user_voucher_logs.id !!
                  navigate(`${item.id}`);
                }}
              >
                {
                  // TODO: change to your own logic for expired coupon
                  item.used ? '已兌換' : '詳情'
                }
              </button>
            </div>
          </div>
        ))}
        {/* {Mock.map((item) => (
          <div key={item.id} className={styles.listItem}>
            <div className={styles.shadow}></div>
            <div className={styles.img}></div>
            <div className={styles.info}>
              <h5>{item.svpt_info}</h5>
              <span className={styles.date}>{item.created_at}</span>
              {renderMethod(item)}
            </div>
            <Link to="../detail" className={styles.infoBtn}>
              <InfoSVG />
            </Link>
          </div>
        ))} */}
      </div>

      <Dialog
        // 22.05.30 SCH, vip 2.0 已不再此頁面使用!!
        visible={redeemVisible}
        onConfirm={onConfirm}
        onCancel={onCancel}
        message={
          <div className={styles.dialogHeader}>
            <h5>歸還獎勵兌換</h5>
            <div>
              <h5>{redeemCode.current}</h5>
            </div>
            <div className={styles.authNum}>
              <p>{authNum.current}</p>
            </div>
            <div className={styles.authNumBottom}>
              <TextField
                type="text"
                label="驗證碼"
                name="auth_num"
                InputLabelProps={{ style: { fontSize: 24 } }}
                InputProps={{ style: { fontSize: 24 } }}
                onChange={(event) =>
                  onFormChange('auth_num', event.target.value)
                }
              />
            </div>
            <p>請至櫃檯出示此畫面來兌換獎勵</p>
            <p>驗證通過後請點擊「{buttonLabel.current}」以完成兌換</p>
          </div>
        }
        cancelText="下次再說"
        okText={buttonLabel.current}
        cancelable
        showOk={isEnabled()}
      />
    </>
  );
};

export default OfflineDiscount;
