import SubmitButton from 'components/SubmitButton';
import styles from './index.module.scss';
import { ReactComponent as LinePaySvg } from 'assets/linepay.svg';
import { useState } from 'react';
import EmailDialog from './components/emailDialog';

const LinkLinePay = () => {
  const [readTerms, setReadTerms] = useState(true);
  const [visible, setVisible] = useState(false);

  const onBackdropClick = () => {
    setVisible(false);
  };
  return (
    <>
      <div className={styles.linkLinePay}>
        <div className={styles.container}>
          <p className={styles.title}>
            <strong>請綁定付款方式以後續使用配客嘉循環包裝服務</strong>
          </p>
          <div className={styles.description}>
            <p>
              費用僅會在循環包裝未在14天內歸還以Linepay自動扣款。詳細規定請至
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.packageplus-tw.com/pages/questionsandanswers"
              >
                Q&A
              </a>
              了解。
            </p>
          </div>
          <div className={styles.paymentMethods}>
            <div className={styles.header}>
              <span>付款方式</span>
            </div>
            <div className={styles.method}>
              <div>
                <LinePaySvg />
                <span>Line Pay</span>
              </div>
              <div>
                <input type="radio" checked readOnly />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.terms}>
            <input
              id="terms"
              type="checkbox"
              checked={readTerms}
              onChange={(event) => {
                setReadTerms(event.target.checked);
              }}
            />
            <label htmlFor="terms">
              請勾選表示您以閱讀{' '}
              <a
                href="https://www.packageplus-tw.com/about/terms"
                target="_blank"
                rel="noreferrer"
              >
                使用條款與隱私權政策
              </a>
            </label>
          </div>
          <SubmitButton
            text="註冊"
            disabled={!readTerms}
            onClick={() => {
              setVisible(true);
            }}
          />
        </div>
      </div>
      <EmailDialog open={visible} onBackdropClick={onBackdropClick} />
    </>
  );
};

export default LinkLinePay;
